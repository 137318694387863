import { FlowId } from 'shared/src/features/flow/flow.types';
import { Flowlist } from 'shared/src/features/flowlist/flowlist.types';
import { WidgetId } from 'shared/src/features/widgets/widgets.types';

import {
  DemoPlayerConfig,
  DemoPlayerFlow,
  DemoPlayerFlowsDict,
  DemoPlayerPageLinksDict,
  DemoPlayerProjectPage,
  DemoPlayerWidgetsDict,
} from '../types';
import { generateFlowIdSequence } from '../utils/generateFlowIdSequence';
import { generatePageIdSequence } from '../utils/generatePageIdSequence';
import {
  assignGoToWidgetEffect,
  assignNextWidgetEffect,
  assignPrevWidgetEffect,
  autoStartChecklistEffect,
  autoStartFlowPlaybackEffect,
  captureLeadEffect,
  continueWalkthroughEffect,
  initialEffect,
  persistChecklistProgressEffect,
  playFlowEffect,
  replayFlowFromChecklistEffect,
  requestedFlowStartEffect,
  requestedWidgetStartEffect,
  resetFlowEffect,
  restartEffect,
  startFlowFromButton,
  startFlowFromChecklistEffect,
  startNextFlowEffect,
} from './machine.actions';
import { demoPlayerModel } from './machine.model';
import { DemoPlayerContextType } from './machine.types';

export type FlowProgressItem = {
  id: FlowId;
  widgets: WidgetId[];
};

// guards -- start
const isFirstWidgetInFlow = (context: DemoPlayerContextType) => {
  return Boolean(
    context.widgetsLinkedMap[context.currentWidgetId as WidgetId]?.prev === null
  );
};
const isLastWidgetInFlow = (context: DemoPlayerContextType) => {
  return !context.widgetsLinkedMap[context.currentWidgetId as WidgetId]?.next;
};

export const isNoChecklist = (
  context: DemoPlayerContextType
): context is DemoPlayerContextType & { checklist: null } => {
  return context.checklist === null;
};
export const isMinimizeChecklist = (
  context: DemoPlayerContextType
): context is DemoPlayerContextType & { checklist: Flowlist } => {
  return (
    isChecklistPresented(context) &&
    (isFlowStartRequested(context) ||
      isWidgetStartRequested(context) ||
      isChecklistAutoplayEnabled(context))
  );
};
export const isChecklistPresented = (
  context: DemoPlayerContextType
): context is DemoPlayerContextType & { checklist: Flowlist } => {
  return context.checklist !== null;
};
const isChecklistAutoplayEnabled = (context: DemoPlayerContextType) => {
  return (
    isChecklistPresented(context) &&
    Boolean(context.checklist.options.root.autoStartEnabled)
  );
};
const isFlowStartRequested = (context: DemoPlayerContextType) => {
  return Boolean(context.config.requestedStartFlowId);
};
const isWidgetStartRequested = (context: DemoPlayerContextType) => {
  return Boolean(context.config.requestedStartWidgetId);
};
const isStartWithWalkthrough = (context: DemoPlayerContextType) => {
  const {
    isWalkthroughTourDisabled,
    requestedStartFlowId,
    requestedStartWidgetId,
  } = context.config;

  if (isWalkthroughTourDisabled) return false;

  /**
   * Check "walkthrough" data is presented.
   * Note: demoplayer can't skip empty "flows".
   */
  if (
    Object.keys(context.widgetsDict).length === 0 ||
    context.flowIdSequence.length === 0 ||
    Object.keys(context.flowsDict).length === 0
  ) {
    return false;
  }

  if (isFlowStartRequested(context)) {
    const requestedFlow = context.flowsDict[requestedStartFlowId as FlowId];
    return Boolean(requestedFlow && requestedFlow.widgets.length > 0);
  }

  if (isWidgetStartRequested(context)) {
    const requestedWidget =
      context.widgetsDict[requestedStartWidgetId as WidgetId];
    const requestedFlow = context.flowsDict[requestedWidget.flowId];
    return Boolean(requestedWidget && requestedFlow);
  }

  /**
   * We already know there are some "widgets" and "flows" from condition above.
   * When "flowlist" is presented AND auto-playback is turned off, then start with "interactive"
   */
  if (isChecklistPresented(context)) {
    return isChecklistAutoplayEnabled(context);
  }

  /**
   * We start playback from the 1st "flow", if there is no "flowlist"
   * Hence, make sure 1st "flow" has "widgets".
   */
  const startFlowId = context.flowIdSequence[0];
  const startFlow = context.flowsDict[startFlowId] as DemoPlayerFlow;

  return startFlow.widgets?.length > 0;
};
const isNextFlowAutoStartAvailable = (context: DemoPlayerContextType) => {
  const isNextFlowExist = Boolean(
    context.flowsLinkedMap[context.currentFlowId as FlowId]
  );

  const isAutoStartEnabled =
    isChecklistPresented(context) &&
    context.checklist.options.root.autoStartEnabled;

  const isChecklistNotComplete =
    isChecklistPresented(context) &&
    context.progress.checklist.length !== context.checklist.items.length;

  return (
    isNextFlowExist &&
    (!isChecklistPresented(context) ||
      (isAutoStartEnabled && isChecklistNotComplete))
  );
};
const isRestartAvailable = (context: DemoPlayerContextType) => {
  return (
    context.config.isReplayEnabled &&
    Object.keys(context.pageLinks).length === 0
  );
};
const isInvalidFlow = (context: DemoPlayerContextType) => {
  /**
   * We need to check flow playback is impossible:
   * 1. Checklist item is connted to NO-flow.
   * 2. Flow is empty
   */
  if (context.currentFlowId) {
    const flow = context.flowsDict[context.currentFlowId];
    return !flow;
  }

  return true;
};
const isEmptyFlow = (context: DemoPlayerContextType) => {
  if (context.currentFlowId) {
    const flow = context.flowsDict[context.currentFlowId];
    return flow ? flow.widgets.length === 0 : false;
  }

  return false;
};
// guards -- end

const demoPlayerMachine = demoPlayerModel.createMachine(
  {
    id: 'demoPlayer',
    type: 'parallel',
    states: {
      checklist: {
        initial: 'ready',
        states: {
          ready: {
            on: {
              startPlayer: [
                {
                  cond: isNoChecklist,
                  target: 'hidden',
                },
                {
                  cond: isMinimizeChecklist,
                  target: 'minimized',
                },
                {
                  cond: isChecklistPresented,
                  target: 'maximized',
                },
              ],
            },
          },
          maximized: {
            on: {
              minimizeChecklist: {
                target: 'minimized',
              },
            },
          },
          minimized: {
            on: {
              maximizeChecklist: {
                target: 'maximized',
              },
            },
          },
          hidden: {
            type: 'final',
          },
          invalid: {
            type: 'final',
          },
        },
        on: {
          trackChecklistItemVisit: {
            internal: true,
            actions: [persistChecklistProgressEffect],
          },
        },
      },
      tour: {
        initial: 'ready',
        states: {
          ready: {
            on: {
              startPlayer: [
                {
                  target: 'walkthrough',
                  cond: isStartWithWalkthrough,
                },
                {
                  target: 'interactive',
                },
              ],
            },
          },
          interactive: {
            initial: 'default',
            entry: 'persistPageInHistoryEffect',
            on: {
              goToPage: {
                internal: true,
                actions: demoPlayerModel.assign((_, event) => ({
                  currentPageId: event.pageId,
                })),
              },
            },
            states: {
              default: {},
              walkthroughFinished: {
                on: {
                  restartPlayer: {
                    target: '#demoPlayer.tour.walkthrough.playFlow',
                    actions: restartEffect,
                  },
                },
              },
              walkthroughDismissed: {
                on: {
                  continueWalkthrough: {
                    target: '#demoPlayer.tour.walkthrough.playFlow',
                    actions: continueWalkthroughEffect,
                  },
                },
              },
            },
          },
          walkthrough: {
            initial: 'unknown',
            states: {
              unknown: {
                always: [
                  {
                    internal: true,
                    target: 'playFlow',
                    cond: isFlowStartRequested,
                    actions: requestedFlowStartEffect,
                  },
                  {
                    internal: true,
                    target: 'playFlow',
                    cond: isWidgetStartRequested,
                    actions: requestedWidgetStartEffect,
                  },
                  {
                    internal: true,
                    target: 'playFlow',
                    cond: isChecklistAutoplayEnabled,
                    actions: autoStartChecklistEffect,
                  },
                  {
                    internal: true,
                    target: 'playFlow',
                    actions: autoStartFlowPlaybackEffect,
                  },
                ],
              },
              playFlow: {
                entry: [playFlowEffect],
                always: [
                  {
                    target: 'finishFlow',
                    cond: isEmptyFlow,
                  },
                  {
                    target: 'invalid',
                    cond: isInvalidFlow,
                  },
                ],
                on: {
                  goNextWidget: [
                    {
                      cond: isLastWidgetInFlow,
                      target: 'finishFlow',
                    },
                    {
                      internal: false,
                      target: 'playFlow',
                      actions: assignNextWidgetEffect,
                    },
                  ],
                  goPrevWidget: [
                    {
                      cond: isFirstWidgetInFlow,
                      target: undefined,
                    },
                    {
                      target: 'playFlow',
                      internal: false,
                      actions: assignPrevWidgetEffect,
                    },
                  ],
                  goToWidget: [
                    {
                      target: 'playFlow',
                      actions: assignGoToWidgetEffect,
                    },
                  ],
                  captureLead: [
                    {
                      target: 'playFlow',
                      internal: false,
                      actions: [captureLeadEffect, 'captureLeadExternalEffect'],
                    },
                  ],
                  startFlowFromButton: {
                    target: 'playFlow',
                    internal: false,
                    actions: startFlowFromButton,
                  },
                },
              },
              waitRestart: {
                on: {
                  restartPlayer: {
                    target: 'playFlow',
                    actions: restartEffect,
                  },
                },
              },
              finishFlow: {
                always: [
                  {
                    cond: isNextFlowAutoStartAvailable,
                    target: 'playFlow',
                    actions: startNextFlowEffect,
                  },
                  {
                    cond: isChecklistPresented,
                    target: [
                      '#demoPlayer.checklist.maximized',
                      '#demoPlayer.tour.interactive',
                    ],
                    actions: resetFlowEffect,
                  },
                  {
                    target: 'complete',
                    actions: resetFlowEffect,
                  },
                ],
              },
              complete: {
                type: 'final',
                entry: ['onFinishWalkthrough'],
                always: [
                  {
                    cond: isRestartAvailable,
                    target: 'waitRestart',
                  },
                  {
                    target: '#demoPlayer.tour.interactive.walkthroughFinished',
                  },
                ],
              },
              invalid: {
                type: 'final',
              },
            },
            on: {
              dismissWidget: {
                target: '#demoPlayer.tour.interactive.walkthroughDismissed',
              },
            },
          },
        },
        on: {
          captureLead: {
            actions: [captureLeadEffect],
          },
          startFlowFromChecklist: {
            target: '#demoPlayer.tour.walkthrough.playFlow',
            actions: startFlowFromChecklistEffect,
          },
          replayFlowFromChecklist: {
            actions: replayFlowFromChecklistEffect,
            target: '#demoPlayer.tour.walkthrough.playFlow',
          },
        },
      },
    },
    entry: [initialEffect],
  },
  {
    guards: {
      isFirstWidgetInFlow,
      isLastWidgetInFlow,
      isChecklistPresented,
      isNextFlowAutoStartAvailable,
      isStartWithWalkthrough,
    },
  }
);
export type DemoPlayerMachineType = typeof demoPlayerMachine;
export default demoPlayerMachine;

interface getInitialContextArgs {
  /**
   * Original sequence of flow ids coming from the BE.
   */
  projectFlowIdSequence: FlowId[];
  projectWidgets: DemoPlayerWidgetsDict;
  projectFlows: DemoPlayerFlowsDict;
  projectPages: DemoPlayerProjectPage[];
  projectPageLinks: DemoPlayerPageLinksDict;
  config: DemoPlayerConfig;
  onWalkthroughFinished?: () => void;
  flowlist?: Flowlist;
}

export const getInitialContext = (
  args: getInitialContextArgs
): DemoPlayerContextType => {
  const {
    flowlist,
    projectWidgets,
    projectFlows,
    projectFlowIdSequence,
    projectPageLinks,
    projectPages,
    config,
  } = args;
  const {
    isPreview = false,
    isWalkthroughTourDisabled = false,
    startFlowIndex,
    startPageId,
    startWidgetId,
    isLeadCaptured = false,
  } = config;

  const commonProps = {
    isLeadCaptured,
    pageLinks: projectPageLinks,
    currentPageId: startPageId,
  };

  /**
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Walkthrough-tour Walkthrough Docs}
   */
  if (!isWalkthroughTourDisabled && projectFlowIdSequence.length) {
    const checklist = flowlist ?? null; // we expect only one(or zero) flowlist for now

    const flowsLinkedMap: Record<FlowId, FlowId | null> = {};
    const widgetsMap: DemoPlayerContextType['widgetsLinkedMap'] = {};
    const flowIdSequence: FlowId[] = generateFlowIdSequence({
      flowlist: checklist,
      flowIdSequence: projectFlowIdSequence,
    });
    flowIdSequence.forEach((currentFlowId, index, collection) => {
      // populate flows map
      const nextFlowId = collection[index + 1] ?? null;
      flowsLinkedMap[currentFlowId] = nextFlowId;
    });

    // populate widgets map
    projectFlowIdSequence.forEach((currentFlowId) => {
      const currentFlow = projectFlows[currentFlowId] as DemoPlayerFlow;
      currentFlow.widgets.reduce((map, widgetId, currentIndex, collection) => {
        const prevWidgetId = collection[currentIndex - 1] ?? null;
        const nextWidgetId = collection[currentIndex + 1] ?? null;

        map[widgetId] = {
          prev: prevWidgetId,
          next: nextWidgetId,
        };
        return map;
      }, widgetsMap);
    });

    return {
      ...commonProps,
      checklist,
      progress: {
        checklist: [],
        flows: Object.keys(projectFlows).map((key) => ({
          id: key,
          widgets: [],
          totalWidgetsCount: projectFlows[key]?.widgets.length || 0,
        })),
      },
      currentFlowId: null,
      currentWidgetId: null,
      flowsDict: projectFlows,
      flowIdSequence,
      /**
       * Predict queue of project pages based on `widgets`, `flowlist`, `flows` and `pageLinks`.
       */
      pageIdSequence: generatePageIdSequence({
        flowlist: flowlist || null,
        widgets: projectWidgets,
        flows: projectFlows,
        pages: projectPages,
        pageLinks: projectPageLinks,
        flowIdSequence,
        startPageId,
      }),
      widgetsDict: projectWidgets,
      flowsLinkedMap,
      widgetsLinkedMap: widgetsMap,
      config: {
        startPageId,
        requestedStartFlowId:
          typeof startFlowIndex === 'number' && flowIdSequence[startFlowIndex]
            ? flowIdSequence[startFlowIndex]
            : null,
        isWalkthroughTourDisabled: false,
        requestedStartWidgetId: startWidgetId || null,
        isPreview,
        isReplayEnabled: config.isReplayEnabled,
        isRecording: config.isRecording,
      },
    };
  }

  return {
    ...demoPlayerModel.initialContext,
    ...commonProps,
    /**
     * Predict queue of project pages based on `pageLinks`.
     * "Guides" are ommitted here.
     */
    pageIdSequence: generatePageIdSequence({
      flowlist: null,
      widgets: {},
      flows: {},
      pages: projectPages,
      pageLinks: projectPageLinks,
      flowIdSequence: [],
      startPageId,
    }),
    config: {
      startPageId,
      requestedStartFlowId: null,
      requestedStartWidgetId: startWidgetId || null,
      isWalkthroughTourDisabled,
      isPreview,
      isReplayEnabled: config.isReplayEnabled,
      isRecording: config.isRecording,
    },
  };
};
