import { CSSProperties, FC, useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import RendererImageFrame from 'shared/src/components/renderer/RendererImageFrame';
import Watermark from 'shared/src/uikit/Watermark';
import { RendererImageFrameHeader } from 'shared/src/components/renderer/RendererImageFrame/RendererImageFrame';
import { useFullScreen } from 'shared/src/hooks/useFullScreen';

import { DemoPlayerProps } from '../DemoPlayer';
import DemoPlayerRendererImage from '../DemoPlayerRendererImage';
import FlowlistLaunchManager from '../FlowlistLaunchManager';
import FlowlistManager from '../FlowlistManager';
import Restart from '../Restart';
import WidgetManagerImage from '../WidgetManagerImage';
import styles from './DemoPlayerImage.module.css';
import CustomCtaLaunchButton from '../CustomCtaLaunchButton';
import WidgetBottomTray from '../WidgetBottomTray';
import { usePlayerState } from '../../hooks/usePlayerState';
import { useDemoIsSmallScreen } from '../../hooks/useDemoIsSmallScreen';
import { useFlowlistPlayerState } from '../../hooks/useFlowlistPlayerState';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import NavigationBarManager from '../NavigationBarManager';
import { demoplayerEventCreators } from '../../events/eventCreators';
import { ExtUrlTarget } from '../DemoPlayer/DemoPlayer';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';

const DemoPlayerImage: FC<{
  isRendererReady: boolean;
  onRendererReady: (isReady: boolean) => void;
  onOpenExtUrl: DemoPlayerProps['onOpenExtUrl'];
  isCustomCtaEnabled?: boolean;
}> = (props) => {
  const { isRendererReady, onRendererReady, onOpenExtUrl, isCustomCtaEnabled } =
    props;
  const {
    playerConfig,
    shouldDisplayBrandWatermark,
    isResponsiveEnabled,
    watermarkLogoHref,
    emitter,
  } = useContext(DemoPlayerContext);

  const {
    projectId,
    isChecklistEnabled,
    isNavigationEnabled,
    autoplayTimeoutSeconds,
    currentWidgetId,
  } = usePlayerState();
  const { rendererWrapper, rendererBorderColor } = useRendererPlayerState();
  const { requestFullScreenView, showFullScreenButton, isFullscreen } =
    useFullScreen();
  const {
    checklist,
    maximizeChecklist,
    checklistIsMinimized,
    minimizeChecklist,
  } = useFlowlistPlayerState();
  const isDemoPlayerSmallScreen = useDemoIsSmallScreen();
  const showNavigationBar = isNavigationEnabled && !isDemoPlayerSmallScreen;
  const showFlowlistLaunch = isChecklistEnabled && !isDemoPlayerSmallScreen;

  const handleFlowlistLaunchClick = useCallback(() => {
    (checklistIsMinimized ? maximizeChecklist : minimizeChecklist)();
  }, [checklistIsMinimized, maximizeChecklist, minimizeChecklist]);

  const rootStyles = useMemo(() => {
    if (!isResponsiveEnabled) {
      return undefined;
    }
    /**
     * @see {@link https://storylane.atlassian.net/wiki/x/FYDyB Calculations}
     */
    return {
      '--renderer-frame-header': `${
        playerConfig.renderer_border_color ? RendererImageFrameHeader : 0
      }px`,
    } as CSSProperties;
  }, [isResponsiveEnabled, playerConfig.renderer_border_color]);

  const handleCustomCTAClick = useCallback(() => {
    emitter.emit(demoplayerEventCreators.customCtaClickEvent, {
      data: {
        url: playerConfig.cta_url,
        target: playerConfig.cta_url_target as ExtUrlTarget, // We expect `_target` value to be presented for enabled cta
      },
      widgetId: currentWidgetId ?? undefined,
      projectId,
    });
  }, [
    emitter,
    currentWidgetId,
    projectId,
    playerConfig.cta_url,
    playerConfig.cta_url_target,
  ]);

  return (
    <div
      className={cx(styles.root, {
        [styles.rootResponsive]: isResponsiveEnabled,
      })}
      data-testid="demoplayer-image"
      style={rootStyles}
    >
      <RendererImageFrame
        isRendererReady={isRendererReady}
        variant={rendererBorderColor}
        showFullScreenButton={showFullScreenButton}
        onFullScreenButtonClick={requestFullScreenView}
        rendererWrapperRef={rendererWrapper}
        isFullscreen={isFullscreen}
        bgColor={playerConfig.renderer_background_color}
        isResponsive={isResponsiveEnabled}
      >
        <div
          className={cx(styles.renderer, {
            [styles.fullScreenRenderer]: isFullscreen,
          })}
        >
          <DemoPlayerRendererImage
            isReady={isRendererReady}
            onReady={onRendererReady}
            isFullscreen={isFullscreen}
          />
        </div>
        {showFlowlistLaunch && (
          <FlowlistLaunchManager isResponsive={isResponsiveEnabled} />
        )}
        {isChecklistEnabled && (
          <div className={styles.flowlist}>
            <FlowlistManager onOpenExtUrl={onOpenExtUrl} />
          </div>
        )}
        <Restart projectId={projectId} />
        {shouldDisplayBrandWatermark && (
          <div className={styles.watermark}>
            <Watermark
              href={watermarkLogoHref}
              tooltipDisabled={isDemoPlayerSmallScreen}
            />
          </div>
        )}
        {isCustomCtaEnabled && isRendererReady && (
          <CustomCtaLaunchButton
            onCustomCtaExtUrl={handleCustomCTAClick}
            playerConfig={playerConfig}
            isResponsiveEnabled={isResponsiveEnabled}
          />
        )}

        {showNavigationBar && <NavigationBarManager />}
        {isDemoPlayerSmallScreen && (
          <WidgetBottomTray
            flowlist={checklist}
            onFlowlistLaunch={handleFlowlistLaunchClick}
            showFlowlistLaunch={isChecklistEnabled}
            rounded
          />
        )}
        <WidgetManagerImage
          isRendererReady={isRendererReady}
          onOpenExtUrl={onOpenExtUrl}
          autoplayTimeoutSeconds={autoplayTimeoutSeconds}
        />
        {
          /**
           * Widget is not always shown, so we need to render dark-colored placeholder.
           * For example:
           * Flow is finished and Flowlist is shown, so we need to show something instead of a widget below the renderer.
           * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/83001365/DRAFT+Responsive#Placeholder Docs}
           */
          isDemoPlayerSmallScreen && (
            <div className={styles.WidgetBottomTrayPlaceholder} />
          )
        }
      </RendererImageFrame>
    </div>
  );
};

export default DemoPlayerImage;
