import { forwardRef } from 'react';
import Icon from 'shared/src/uikit/Icon';

import styles from './WidgetBottomTrayFlowlistLaunch.module.css';

interface WidgetBottomTrayFlowlistLaunchProps {
  onClick: () => void;
}

const WidgetBottomTrayFlowlistLaunch = forwardRef<
  HTMLButtonElement,
  WidgetBottomTrayFlowlistLaunchProps
>((props, ref) => {
  const { onClick } = props;

  return (
    <button
      onClick={onClick}
      className={styles.launchProductTour}
      data-testid="flowlist-launch"
      aria-label="Launch Product Tour From Bottom Tray"
      ref={ref}
    >
      <Icon width={13} height={10} name="menu" />
    </button>
  );
});
WidgetBottomTrayFlowlistLaunch.displayName = 'WidgetBottomTrayFlowlistLaunch';

export default WidgetBottomTrayFlowlistLaunch;
