import { FC, ReactNode } from 'react';
import {
  HotspotWidget,
  TooltipWidget,
} from 'shared/src/features/widgets/widgets.types';
import { isModalLikeWidget } from 'shared/src/features/widgets/widgets.utils';

import TooltipPositionManagerImage from './TooltipPositionManagerImage';
import WidgetDialogPositionManager from '../WidgetDialogPositionManager';
import { DemoPlayerWidget } from '../../types';

interface WidgetPositionManagerImageProps {
  widget: DemoPlayerWidget;
  children: ReactNode;
  widgetSlotElement: HTMLDivElement;
  isDemoPlayerSmallScreen: boolean;
}

const WidgetPositionManagerImage: FC<WidgetPositionManagerImageProps> = ({
  children,
  widget,
  widgetSlotElement,
  isDemoPlayerSmallScreen,
}) => {
  if (isModalLikeWidget(widget)) {
    return (
      <WidgetDialogPositionManager>{children}</WidgetDialogPositionManager>
    );
  }

  return (
    <TooltipPositionManagerImage
      widget={widget as DemoPlayerWidget<TooltipWidget | HotspotWidget>}
      widgetSlotElement={widgetSlotElement}
      isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
    >
      {children}
    </TooltipPositionManagerImage>
  );
};

export default WidgetPositionManagerImage;
