import { FC, ImgHTMLAttributes, useEffect } from 'react';

import { useLoadImage } from '../../hooks/useLoadImage';
import Skeleton from '../Skeleton';

interface ImageProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'onLoad'> {
  src: string;
  onLoad?: (image: HTMLImageElement) => void;
}

const Image: FC<ImageProps> = ({ src, width, height, onLoad, ...props }) => {
  const [image, imageLoading] = useLoadImage(src);

  useEffect(() => {
    if (image && !imageLoading) {
      onLoad?.(image);
    }
  }, [image, imageLoading, onLoad]);

  if (imageLoading) {
    return <Skeleton width={width} height={height} fill />;
  }

  // Need fallback
  if (!image) {
    return null;
  }

  return <img src={image.src} width={width} height={height} {...props} />;
};

export default Image;
