import { useMemo } from 'react';
import { PopupWidget } from 'shared/src/features/widgets/widgets.types';
import {
  calculateWidgetTextColor,
  retrieveWidthStyles,
} from 'shared/src/features/widgets/widgets.utils';
import WidgetScrollableContainer from 'shared/src/components/widgets/WidgetScrollableContainer';
import WidgetFooter from 'shared/src/components/widgets/WidgetFooter';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import WidgetText from 'shared/src/components/widgets/WidgetText';
import WidgetBody from 'shared/src/components/widgets/WidgetBody';

import { WidgetComponentProps } from '../Widget';
import styles from './Popup.module.css';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';

function Popup({
  widget,
  onCta,
  isMounted,
  isDemoPlayerSmallScreen,
  onExit,
  onReady,
  onPrev,
  onSecondary,
  onDismiss,
  animationDelayMs,
  durationIn,
  durationOut,
}: WidgetComponentProps<PopupWidget>): JSX.Element {
  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';
  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth]
  );

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn="top"
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetBody
        onDismiss={onDismiss}
        widget={widget}
        className={styles.root}
        shouldAddWatermark={widget.watermarkEnabled}
        watermarkHref={widget.watermarkTextHref}
        testId={`widget_${widget.kind}_${widget.id}`}
        id={`popup_widget_${widget.id}`}
        {...widthStyles}
      >
        <WidgetScrollableContainer>
          <WidgetText
            value={widget.options.description.value}
            style={{
              color: calculateWidgetTextColor(widgetBackgroundColor, 'primary'),
            }}
          />
        </WidgetScrollableContainer>
        {!isDemoPlayerSmallScreen && (
          <WidgetFooter
            totalStepsNumber={widget._totalStepsCount}
            currentStepNumber={widget._currentStepNumber}
            widget={widget}
            onSecondary={onSecondary}
            onPrev={onPrev}
            onCta={onCta}
            showPrevButton={isWidgetPreviousButtonEnabled(widget)}
            id={`popup_widget_footer_${widget.id}`}
          />
        )}
      </WidgetBody>
    </WidgetAnimation>
  );
}

export default Popup;
