import { ComponentProps } from 'react';
import {
  BaseWidget,
  HotspotWidget,
  TooltipWidget,
} from 'shared/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isAnchorBasedWidgetBackdropEnabled,
  isModalLikeWidget,
} from 'shared/src/features/widgets/widgets.utils';
import WidgetTooltipBackdropImage from 'shared/src/components/widgets/WidgetTooltipBackdropImage';
import WidgetDialogBackdrop from 'shared/src/components/widgets/WidgetDialogBackdrop';
import WidgetBackdropAnimation from 'shared/src/components/widgets/WidgetBackdropAnimation';

import { DemoPlayerWidget } from '../../types';

interface WidgetBackdropManagerImageProps
  extends Pick<
    ComponentProps<typeof WidgetBackdropAnimation>,
    'durationIn' | 'durationOut'
  > {
  widget: DemoPlayerWidget<BaseWidget>;
  isMounted: boolean;
  animationDelayMs: number;
  onClick: () => void;
}

function WidgetBackdropManagerImage({
  widget,
  isMounted,
  animationDelayMs,
  onClick,
  durationIn,
  durationOut,
}: WidgetBackdropManagerImageProps): JSX.Element {
  return (
    <WidgetBackdropAnimation
      delayIn={animationDelayMs}
      durationIn={durationIn}
      durationOut={durationOut}
      in={isMounted}
    >
      {isModalLikeWidget(widget) && <WidgetDialogBackdrop />}
      {isAnchorBasedWidget(widget) &&
        isAnchorBasedWidgetBackdropEnabled(widget) && (
          <WidgetTooltipBackdropImage
            widget={widget as DemoPlayerWidget<HotspotWidget | TooltipWidget>}
            onClick={onClick}
          />
        )}
    </WidgetBackdropAnimation>
  );
}

export default WidgetBackdropManagerImage;
