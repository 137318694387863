import { CSSProperties, ReactNode, useContext, useState } from 'react';
import cx from 'classnames';
import {
  HotspotWidget,
  TooltipWidget,
} from 'shared/src/features/widgets/widgets.types';
import WidgetTooltipAlignment from 'shared/src/components/widgets/WidgetTooltipAlignment';
import WidgetHotspotAlignment from 'shared/src/components/widgets/WidgetHotspotAlignment';
import {
  isHotspot,
  isTooltip,
} from 'shared/src/features/widgets/widgets.utils';

import { useHtmlAnchorPosition } from './TooltipPositionManagerHtml.hooks';
import styles from '../WidgetPositionManagerHtml.module.css';
import { DemoPlayerWidget } from '../../../types';
import { DemoPlayerWidgetContext } from '../../../contexts/DemoPlayerWidgetContext';

interface TooltipPositionManagerHtmlProps {
  widget: DemoPlayerWidget<TooltipWidget | HotspotWidget>;
  rendererElement: HTMLIFrameElement;
  children: ReactNode;
  isDemoPlayerSmallScreen: boolean;
}

const TooltipPositionManagerHtml = ({
  widget,
  rendererElement,
  children,
  isDemoPlayerSmallScreen,
}: TooltipPositionManagerHtmlProps) => {
  const [positionElement, setPositionElement] = useState<HTMLDivElement | null>(
    null
  );
  const [positionStyles, setPositionStyles] = useState<{
    '--translate-x': string;
    '--translate-y': string;
  }>();
  const { isWidgetScrollAnimationDisabled } = useContext(
    DemoPlayerWidgetContext
  );
  const anchorIsNotAvailable = useHtmlAnchorPosition({
    rendererElement,
    anchorElementSelector: widget.dom_id,
    offset: widget.options.root.offset,
    offsetFromOriginPx: widget.options.root.offsetFromOriginPx,
    widgetPositionElement: positionElement,
    onPositionChange: setPositionStyles,
    isAnimated: !isWidgetScrollAnimationDisabled,
  });

  return (
    <div
      ref={setPositionElement}
      className={cx(styles.root, styles.rootTooltip, {
        [styles.rootAnchorNotFound]: anchorIsNotAvailable,
      })}
      style={positionStyles as CSSProperties | undefined}
    >
      {isTooltip(widget) && (
        <WidgetTooltipAlignment
          isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
          alignment={widget.options.root.alignment}
        >
          {children}
        </WidgetTooltipAlignment>
      )}
      {isHotspot(widget) && (
        <WidgetHotspotAlignment
          alignment={widget.options.root.alignment}
          onlyBeacon={widget.hotspotOnlyBeacon || isDemoPlayerSmallScreen}
        >
          {children}
        </WidgetHotspotAlignment>
      )}
    </div>
  );
};

export default TooltipPositionManagerHtml;
