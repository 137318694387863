import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NormalizedSchema } from 'normalizr';
// eslint-disable-next-line import/default
import QueryString, { parse, ParsedQs, stringify } from 'qs';
import { useMachine } from '@xstate/react';
import xor from 'lodash-es/xor';
import fromPairs from 'lodash-es/fromPairs';
import { captureException } from '@sentry/react';
import { wrapSubstitutionKey } from 'shared/src/features/substitution/substitution.utils';
import { ProjectId } from 'shared/src/features/project/project.types';
import {
  localStorageWithFallback,
  sessionStorageWithFallback,
} from 'shared/src/utils/storageWithFallback';
import { SubstitutionValue } from 'shared/src/features/substitution/substitution.types';
import { ProjectPageId } from 'shared/src/features/projectPages/projectPages.types';
import { WidgetId } from 'shared/src/features/widgets/widgets.types';
import { embedIframeName } from 'shared/src/features/ui/ui.constants';
import {
  demoplayerEventCreators,
  DemoPlayerEventEmitter,
  DemoPlayerTextSubstitutionsDict,
} from 'demoplayer';
import { ProjectKind } from 'shared/src/features/project/project.constants';
import observeIntersection from 'shared/src/utils/IntersectionObserver';
import { useEffectOnce } from 'shared/src/hooks/react-use/useEffectOnce';
import { injectProjectScriptsIntoDocument } from 'shared/src/utils/injectProjectScriptsIntoDocument';
import { removeProjectScriptsFromDocument } from 'shared/src/utils/removeProjectScriptsFromDocument';
import { DemoPlayerRootScript } from 'demoplayer/src/types';
import { flattenObject } from 'shared/src/utils/flattenObject';
import {
  DemoPageSearchParams,
  DemoPageEmbedSearchParamValue,
} from 'shared/src/features/demoPageSearchParams/demoPageSearchParams.constants';

import { publicDemoMachine } from './PublicDemoPage.machine';
import {
  createCrossOriginEventMessage,
  getCookiesEmailRequiredLead,
  getQueryLead,
  getSessionData,
  getCookiesSessionLead,
  sendTrackingData,
  sendVisitData,
  SendVisitDataProps,
  sendLeadCapturedVACreator,
  sendGAEvent,
  sendFlowChangedVACreator,
  sendPageChangedVACreator,
  sendFlowEndVACreator,
  sendFlowlistItemClickVACreator,
  sendProjectResolvedVACreator,
  sendWidgetCTAClickVACreator,
  sendOpenedExternalUrlVACreator,
  sendDemoFinishedVACreator,
  sendWidgetSecondaryClickVACreator,
  ThirdPartyAnalyticsEventReturn,
  sendWidgetChangedVACreator,
  sendCustomCtaVACreator,
} from './PublicDemoPage.utils';
import {
  AnalyticsSessionEventValue,
  CrossOriginDemoHostSource,
  CrossOriginEmbedDemoEvent,
  CrossOriginHostInfoEvent,
  CrossOriginIdentifyUserMessage,
  CrossOriginSessionEventEvent,
  CrossOriginTokenSubmitMessage,
  CrossOriginTrackingSource,
} from './PublicDemoPage.constants';
import {
  NormalizedPublishedFlows,
  NormalizedPublishedProject,
  NormalizedPublishedWidgets,
} from './api/project.api';
import { PublishedFormConfig } from './LeadForm/LeadForm.types';
import { ExtUrlTarget, PublicDemoLead } from './PublicDemoPage.types';
import {
  ChromeEventActionTypes,
  useListenForChromeExtensionMessages,
} from 'hooks/useListenForChromeExtensionMessages';

interface IGenericPublicDemoProps {
  search: string;
}

export const useQuerySubstitutionsText = ({
  search,
}: IGenericPublicDemoProps): DemoPlayerTextSubstitutionsDict => {
  const query = useMemo(() => parse(search), [search]);
  const querySubstitutionsValue = query[DemoPageSearchParams.Token];

  // no value
  if (!querySubstitutionsValue) {
    return {};
  }

  // warn incorrect value
  if (
    typeof querySubstitutionsValue === 'string' ||
    Array.isArray(querySubstitutionsValue) ||
    Object.values(querySubstitutionsValue).some(
      (value) => typeof value !== 'string'
    )
  ) {
    console.warn(
      `Incorrect query params "${DemoPageSearchParams.Token}"`,
      querySubstitutionsValue
    );
    return {};
  }

  return Object.entries(
    querySubstitutionsValue as { [key: string]: string }
  ).reduce<DemoPlayerTextSubstitutionsDict>((dictionary, [key, value]) => {
    dictionary[wrapSubstitutionKey(key)] = value;
    return dictionary;
  }, {});
};

/**
 * Retrieve query params related to the "public demo page"\"demo-player" configuration
 *
 * @returns
 *
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393234/URL+search+params Docs}
 */
export const useQueryFlags = ({
  search,
}: IGenericPublicDemoProps): {
  /**
   * If true, disables "walkthrough tour"(showing widgets) in demo-player;
   */
  forceHideSteps: boolean;
  /**
   * If true, disables "lead form"(not widget.kind === lead_capture).
   */
  forceHideForms: boolean;
  /**
   * Project's flow index "demo-player" is forced to start with.
   */
  forceStartFlowIndex?: number;
  /**
   * If true, enable renderer-html scaling.
   */
  scale: boolean | null;
  /**
   * If true, it means /demo is embed inline
   */
  embedInline: boolean;
  /**
   * Parsed location.search string object
   */
  query: ParsedQs;
  /**
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/115539985/VCA#Demoplayer-in-recording-mode Recording mode docs}
   */
  recording: boolean;
  /**
   * seconds
   * @see {@link https://storylane.atlassian.net/browse/STORY-1537 STORY-1537}
   */
  autoPlay?: number;
  /**
   * @see {@link https://storylane.atlassian.net/browse/STORY-1537 STORY-1537}
   */
  disableAnimations: boolean;
} => {
  const query = useRef<ParsedQs | null>(null);

  if (!query.current) {
    query.current = parse(search);
  }

  const flags = getQueryFlags(query.current);

  return {
    ...flags,
    query: query.current,
  };
};

/**
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393234/Public+Demo+URL+search+params
 * @param parsedQuery
 * @returns
 */
const getQueryFlags = (parsedQuery: ParsedQs) => {
  const recording = parsedQuery[DemoPageSearchParams.Recording] === 'true';

  const forceHideSteps =
    parsedQuery[DemoPageSearchParams.HideSteps] === 'true' ||
    parsedQuery[DemoPageSearchParams.HideAll] === 'true';

  const forceHideForms =
    parsedQuery[DemoPageSearchParams.HideForms] === 'true' ||
    parsedQuery[DemoPageSearchParams.HideAll] === 'true' ||
    recording;

  const scale =
    typeof parsedQuery[DemoPageSearchParams.Scale] === 'undefined'
      ? null
      : parsedQuery[DemoPageSearchParams.Scale] === 'true';

  const flowIndex = Number(parsedQuery[DemoPageSearchParams.Flow]); // it starts with "1"

  const autoPlay = Number(parsedQuery[DemoPageSearchParams.AutoPlay]);

  const disableAnimations =
    parsedQuery[DemoPageSearchParams.Animation] === 'false';

  const embedInline =
    parsedQuery[DemoPageSearchParams.Embed] ===
    DemoPageEmbedSearchParamValue.Inline;

  return {
    forceHideSteps,
    forceHideForms,
    scale,
    forceStartFlowIndex: !isNaN(flowIndex) ? flowIndex - 1 : undefined,
    recording,
    autoPlay: isNaN(autoPlay) ? undefined : autoPlay,
    disableAnimations,
    embedInline,
  };
};

interface usePublicDemoMachineProps extends IGenericPublicDemoProps {
  demoId: string;
  baseUrl: string;
  publishedProject: NormalizedSchema<
    {
      projects: Record<string, NormalizedPublishedProject>;
      flows?: NormalizedPublishedFlows;
      widgets?: NormalizedPublishedWidgets;
    },
    {
      form: PublishedFormConfig;
      project: ProjectId;
      substitutions: SubstitutionValue[];
    }
  >;
  path: string;
}
export const usePublicDemoMachine = (props: usePublicDemoMachineProps) => {
  const { demoId, baseUrl, search, publishedProject } = props;
  const { forceHideForms } = useQueryFlags({ search });

  const { result, entities } = publishedProject;
  const project = entities.projects[result.project];

  const emailRequiredLead = getCookiesEmailRequiredLead(demoId);
  const sessionLead = getCookiesSessionLead(demoId);
  const queryLead = getQueryLead(props.path);

  const widgets = entities.widgets ?? {};
  const flows = entities.flows ?? {};

  const [machine] = useState(() =>
    publicDemoMachine.withContext({
      demoId,
      isKnownLead: false,
      flags: {
        forceHideForms,
      },
      lead: emailRequiredLead || sessionLead || queryLead || null,
      baseUrl,
      leadFormConfig: result.form,
      project,
      substitutions: result.substitutions,
      flows,
      widgets,
    })
  );

  return useMachine(machine, {
    devTools: localStorageWithFallback.getItem('xstateDebug') === 'on',
  });
};

interface UseCrossOriginCommunicationProps {
  enabled: boolean;
  enabledRecordingStartEvent: boolean;
  enabledWidgetReadyEvent: boolean;
  project: NormalizedPublishedProject;
  flows: NormalizedPublishedFlows | null;
  widgets: NormalizedPublishedWidgets | null;
  demoId: string;
  query: QueryString.ParsedQs;
  baseUrl: string;
  demoUrl: string;
  demoPlayerEventEmitter: DemoPlayerEventEmitter;
}
/**
 * Communicate with a host application.
 * Host application - client's app which ebmeds our demo.
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Host-app-Message-Communication
 * @param param0
 */
export function useCrossOriginCommunication({
  enabled,
  demoId,
  project,
  flows,
  widgets,
  query,
  baseUrl,
  demoUrl,
  enabledRecordingStartEvent,
  enabledWidgetReadyEvent,
  demoPlayerEventEmitter,
}: UseCrossOriginCommunicationProps): {
  textSubstitutions: DemoPlayerTextSubstitutionsDict;
  onInit: () => void;
  onLeadCapture: (lead: PublicDemoLead) => void;
  onOpenExternalUrl: (
    data: { url: string; target: ExtUrlTarget },
    widgetId?: WidgetId
  ) => void;
  onWalkthroughFinished: () => void;
  onRecordingStop: () => void;
  onCustomCtaExternalUrl: (
    data: { url: string; target: ExtUrlTarget },
    widgetId?: WidgetId
  ) => void;
} {
  const [isInit, setIsInit] = useState(false);
  const [textSubstitutions, setTextSubstitutions] = useState({});
  const projectId = project?.id;
  const companyId = project?.company_id;
  const demoName = project?.name || '';

  const sendCORSMessage = useCallback(
    (message: ReturnType<typeof createCrossOriginEventMessage>) => {
      window.parent.postMessage(message, '*'); // expected to be /share, /hub OR customers's website window
      window.postMessage(message, '*'); // same window of /demo page
    },
    []
  );

  const { eventData: chromeUserEventData } =
    useListenForChromeExtensionMessages<{
      user_id: string;
    }>({
      eventAction: ChromeEventActionTypes.StorylaneShareUserId,
      postMessageAction: ChromeEventActionTypes.StorylaneSharePageDemoReady,
    });

  const user_id = chromeUserEventData?.user_id;

  useEffect(() => {
    if (!projectId || !companyId) return;

    const handleMessageReceive = (messageEvent: MessageEvent) => {
      try {
        if (
          Boolean(messageEvent.data?.tracking) &&
          messageEvent.data?.source === CrossOriginTrackingSource
        ) {
          const referrer =
            Boolean(messageEvent.data.ref) &&
            typeof messageEvent.data.ref.referrer === 'string'
              ? messageEvent.data.ref.referrer
              : null;
          const originalReferrer =
            Boolean(messageEvent.data.ref) &&
            typeof messageEvent.data.ref.originalReferrer === 'string'
              ? messageEvent.data.ref.originalReferrer
              : null;
          // send "tracking" call with tracking data
          sendTrackingData({
            companyId,
            projectId,
            demoId,
            tracking: messageEvent.data.tracking,
            referrer,
            originalReferrer,
            baseUrl,
          });
        } else if (
          Boolean(messageEvent.data?.lead) &&
          messageEvent.data?.source === CrossOriginDemoHostSource
        ) {
          /**
           * send "lead capture" event with client tracking data
           * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Host-app-Message-Communication Docs}
           */
          sendVisitData({
            demoId,
            projectId,
            companyId,
            event: AnalyticsSessionEventValue.LeadCaptured,
            query,
            clientTracking: messageEvent.data.lead,
            baseUrl,
            userId: user_id,
          });
        } else if (
          messageEvent.data?.message === CrossOriginTokenSubmitMessage &&
          Boolean(messageEvent.data?.payload.token) &&
          typeof messageEvent.data?.payload.token === 'object'
        ) {
          /**
           * receive text substitutions from vendor lead form
           * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/10551301/Substitutions#Send-text-substitutions-cross-origin Docs}
           */
          setTextSubstitutions(messageEvent.data.payload.token);
        } else if (
          messageEvent.data?.message === CrossOriginIdentifyUserMessage &&
          Boolean(messageEvent.data?.payload) &&
          typeof messageEvent.data?.payload === 'object'
        ) {
          /**
           * send "lead capture" event with client tracking data
           * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Host-app-Message-Communication Docs}
           */
          sendVisitData({
            demoId,
            projectId,
            companyId,
            event: AnalyticsSessionEventValue.LeadCaptured,
            query,
            clientTracking: messageEvent.data.payload,
            baseUrl,
            userId: user_id,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    window.addEventListener('message', handleMessageReceive);

    return () => {
      window.removeEventListener('message', handleMessageReceive);
    };
  }, [demoId, projectId, companyId, query, baseUrl, user_id]);

  useEffect(() => {
    const messageTarget = window.parent;

    /**
     * Handle client's app custom data tracking.
     */
    if (enabled && !isInit) {
      setIsInit(true);
      // send "ready" message to client's app window.
      messageTarget.postMessage(
        {
          source: CrossOriginEmbedDemoEvent,
          ready: true,
        },
        '*'
      );
    }
  }, [enabled, isInit]);

  const onInit = useCallback(() => {
    sendCORSMessage(
      createCrossOriginEventMessage({
        event: CrossOriginSessionEventEvent.ProjectResolved,
        demoId,
        demoUrl,
        demoName,
      })
    );
  }, [sendCORSMessage, demoId, demoName, demoUrl]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.pageChangeEvent,
      ({ pageId }) => {
        const page = project?.pages.find((page) => page.id === pageId);
        if (!page) {
          console.error(`Unable to find page by id: ${pageId}`);
          return;
        }
        sendCORSMessage(
          createCrossOriginEventMessage({
            event: CrossOriginSessionEventEvent.PageChanged,
            demoUrl,
            demoId,
            page: {
              id: page.id,
              name: page.name,
            },
            demoName,
          })
        );
      }
    );
  }, [
    demoId,
    demoName,
    demoUrl,
    project.pages,
    demoPlayerEventEmitter,
    sendCORSMessage,
  ]);

  useEffect(() => {
    if (!enabledRecordingStartEvent) return;

    const cb = ({ pageId }: { pageId: ProjectPageId }) => {
      const page = project?.pages.find((page) => page.id === pageId);
      if (!page) {
        console.error(`Unable to find page by id: ${pageId}`);
        return;
      }
      /**
       * Send `demo_recording_start` cross-origin event only in "recording mode"
       * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/115539985/VCA#Demoplayer-in-recording-mode
       */
      sendCORSMessage(
        createCrossOriginEventMessage({
          event: CrossOriginSessionEventEvent.RecordingStart,
          demoUrl,
          demoId,
          page: {
            id: page.id,
            name: page.name,
          },
          demoName,
        })
      );
    };

    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.recordingStartReadyEvent,
      cb
    );
  }, [
    sendCORSMessage,
    enabledRecordingStartEvent,
    demoPlayerEventEmitter,
    demoId,
    demoName,
    demoUrl,
    project.pages,
  ]);

  const onRecordingStop = useCallback(() => {
    sendCORSMessage(
      createCrossOriginEventMessage({
        event: CrossOriginSessionEventEvent.RecordingStop,
        demoUrl,
        demoId,
        demoName,
      })
    );
  }, [demoId, demoName, demoUrl, sendCORSMessage]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetChangeEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.WidgetChanged,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              widget: {
                id: widget.id,
                index: widgetIndex,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    sendCORSMessage,
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
  ]);

  useEffect(() => {
    if (!enabledWidgetReadyEvent) return;

    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetReadyEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          /**
           * Send `step_rendered` cross-origin event only in "recording mode"
           * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/115539985/VCA#Demoplayer-in-recording-mode
           */
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.WidgetReady,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              widget: {
                id: widget.id,
                index: widgetIndex,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    sendCORSMessage,
    widgets,
    enabledWidgetReadyEvent,
    flows,
    demoId,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetCtaClickEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.WidgetCtaClick,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              widget: {
                id: widget.id,
                index: widgetIndex,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    demoPlayerEventEmitter,
    sendCORSMessage,
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetSecondaryClickEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.WidgetSecondaryClick,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              widget: {
                id: widget.id,
                index: widgetIndex,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    demoPlayerEventEmitter,
    sendCORSMessage,
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.flowChangeEvent,
      ({ flowId }) => {
        const flow = flows?.[flowId];
        if (flow) {
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.FlowChanged,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    sendCORSMessage,
    demoPlayerEventEmitter,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.lastWidgetEnterEvent,
      ({ flowId }) => {
        const flow = flows?.[flowId];
        if (flow) {
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.FlowEnd,
              demoId,
              demoUrl,
              flow: {
                id: flow.id,
                name: flow.name,
              },
              demoName,
            })
          );
        }
      }
    );
  }, [
    widgets,
    sendCORSMessage,
    flows,
    demoId,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
  ]);

  const onOpenExternalUrl = useCallback(
    (data: { url?: string; target: ExtUrlTarget }, widgetId?: WidgetId) => {
      let widgetIndex: number | null = null;
      let flowId: string | null = null;
      let flowName: string | null = null;

      if (widgetId) {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
        }
        flowName = flow?.name || null;
        flowId = flow?.id || null;
      }
      const widgetValue =
        widgetIndex !== null && widgetId
          ? {
              id: widgetId,
              index: widgetIndex,
            }
          : undefined;
      sendCORSMessage(
        createCrossOriginEventMessage({
          event: CrossOriginSessionEventEvent.OpenedExternalUrl,
          demoId,
          demoUrl,
          demoName,
          widget: widgetValue,
          flow:
            flowId && flowName
              ? {
                  id: flowId,
                  name: flowName,
                }
              : undefined,
          extUrl: data?.url || '',
          extUrlTarget: data.target,
        })
      );
    },
    [demoId, demoName, sendCORSMessage, demoUrl, flows, widgets]
  );

  const onCustomCtaExternalUrl = useCallback(
    (data: { url: string; target: ExtUrlTarget }, widgetId?: WidgetId) => {
      let widgetIndex: number | null = null;
      let flowId: string | null = null;
      let flowName: string | null = null;

      if (widgetId) {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
        }
        flowName = flow?.name || null;
        flowId = flow?.id || null;
      }
      const widgetValue =
        widgetIndex !== null && widgetId
          ? {
              id: widgetId,
              index: widgetIndex,
            }
          : undefined;
      sendCORSMessage(
        createCrossOriginEventMessage({
          event: CrossOriginSessionEventEvent.CustomCta,
          demoId,
          demoUrl,
          demoName,
          widget: widgetValue,
          flow:
            flowId && flowName
              ? {
                  id: flowId,
                  name: flowName,
                }
              : undefined,
          extUrl: data?.url || '',
          extUrlTarget: data.target,
        })
      );
    },
    [demoId, demoName, sendCORSMessage, demoUrl, flows, widgets]
  );

  const onLeadCapture = useCallback(
    (lead: PublicDemoLead) => {
      sendCORSMessage(
        createCrossOriginEventMessage({
          event: CrossOriginSessionEventEvent.LeadCaptured,
          demoId,
          demoUrl,
          demoName,
          lead,
        })
      );
    },
    [demoId, demoName, demoUrl, sendCORSMessage]
  );

  useEffect(
    () =>
      demoPlayerEventEmitter.on(
        demoplayerEventCreators.flowlistItemClickEvent,
        ({ itemId }) => {
          sendCORSMessage(
            createCrossOriginEventMessage({
              event: CrossOriginSessionEventEvent.FlowlistItemClick,
              demoId,
              demoUrl,
              demoName,
              flowlistItemId: itemId,
            })
          );
        }
      ),
    [
      widgets,
      flows,
      demoId,
      demoUrl,
      demoName,
      demoPlayerEventEmitter,
      sendCORSMessage,
    ]
  );

  const onWalkthroughFinished = useCallback(() => {
    sendCORSMessage(
      createCrossOriginEventMessage({
        event: CrossOriginSessionEventEvent.DemoFinished,
        demoId,
        demoUrl,
        demoName,
      })
    );
  }, [demoId, demoName, demoUrl, sendCORSMessage]);

  return {
    textSubstitutions,
    onInit,
    onOpenExternalUrl,
    onLeadCapture,
    onWalkthroughFinished,
    onCustomCtaExternalUrl,
    onRecordingStop,
  };
}

export function useDocumentInViewport() {
  const [isInViewportOnce, setIsInViewportOnce] = useState(false);
  useEffect(() => {
    if (!isInViewportOnce) {
      try {
        const unsubscribe = observeIntersection({
          root: null,
          element: window.document.documentElement,
          threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
          cb(data) {
            if (data[0].intersectionRatio > 0.5) {
              setIsInViewportOnce(true);
              unsubscribe();
            }
          },
        });

        return unsubscribe;
      } catch (error) {
        setIsInViewportOnce(true);
      }
    }
  }, [isInViewportOnce]);
  return isInViewportOnce;
}

/**
 * `usePublicDemoAnalytics` hook can be used to send analytics events on particular actions.
 *
 * @param demoId /share/:demoId OR /demo/:demoId
 * @return
 *
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/360449/Analytics+Events Events Documentation}
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Session Session Documentation}
 *
 */
export function useAnalytics({
  demoId,
  hostInfo,
  query,
  baseUrl,
  demoPlayerEventEmitter,
  project,
  disabled = false,
  waitUntilInViewport = true,
}: {
  project: NormalizedPublishedProject;
  demoId: string;
  query: ParsedQs;
  hostInfo: UsePublicDemoHostInfoResponse;
  baseUrl: string;
  demoPlayerEventEmitter: DemoPlayerEventEmitter;
  disabled?: boolean;
  waitUntilInViewport?: boolean;
}): {
  onOpenExternalUrl: (
    url: string,
    widgetId?: WidgetId,
    projectId?: ProjectId
  ) => void;
} {
  const isInViewportOnce = useDocumentInViewport();

  const accumulateAnalyticsEventsRef = useRef(
    waitUntilInViewport && !isInViewportOnce
  );
  const eventsAccumRef = useRef<SendVisitDataProps[]>([]);
  useEffect(() => {
    if (waitUntilInViewport && isInViewportOnce) {
      accumulateAnalyticsEventsRef.current = false;
      eventsAccumRef.current.forEach((eventData) => {
        sendVisitData(eventData);
      });
    }
  }, [isInViewportOnce, waitUntilInViewport]);
  const dispatchAnalyticsEvent = useMemo(() => {
    return (eventPayload: SendVisitDataProps) => {
      if (disabled) {
        return;
      }

      if (accumulateAnalyticsEventsRef.current) {
        eventsAccumRef.current.push(eventPayload);
        return;
      }

      sendVisitData(eventPayload);
    };
  }, [disabled]);

  const { eventData: chromeUserEventData } =
    useListenForChromeExtensionMessages<{
      user_id: string;
    }>({
      eventAction: ChromeEventActionTypes.StorylaneShareUserId,
      postMessageAction: ChromeEventActionTypes.StorylaneSharePageDemoReady,
    });
  const userIdRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    userIdRef.current = chromeUserEventData?.user_id;
  }, [chromeUserEventData?.user_id]);

  useEffect(() => {
    // "opened" event is sent only once per session.
    const sessionData = getSessionData(demoId);
    if (sessionData.state[AnalyticsSessionEventValue.Opened]) return;

    dispatchAnalyticsEvent({
      demoId,
      projectId: project.id,
      companyId: project.company_id,
      event: AnalyticsSessionEventValue.Opened,
      query,
      hostUrl: hostInfo.current?.url,
      urlQuery: hostInfo.current?.query,
      baseUrl,
      userId: userIdRef.current,
    });
  }, [dispatchAnalyticsEvent, project, demoId, query, hostInfo, baseUrl]);

  const flowListVisitedStorageName = 'flv_' + demoId;
  const flowlist = project?.flowlists[0];
  const flowListIdsPairs = flowlist?.items.map((item) => [item.id, false]);
  const flowListVisitedMap = fromPairs(flowListIdsPairs);

  // Set flowlist storage values
  useEffect(() => {
    if (Object.keys(flowListVisitedMap).length) {
      const currentFlowListVisitedValue = sessionStorageWithFallback.getItem(
        flowListVisitedStorageName
      );
      const currentFlowListVisitedValueObject = JSON.parse(
        currentFlowListVisitedValue || '{}'
      );

      const differenceFromStorage = xor(
        Object.keys(currentFlowListVisitedValueObject),
        Object.keys(flowListVisitedMap)
      );

      if (!currentFlowListVisitedValue || differenceFromStorage.length > 0) {
        sessionStorageWithFallback.setItem(
          flowListVisitedStorageName,
          JSON.stringify(flowListVisitedMap)
        );
      }
    }
  }, [demoId, flowListVisitedMap, flowListVisitedStorageName, project]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.flowChangeEvent,
      ({ flowId }) =>
        dispatchAnalyticsEvent({
          demoId: demoId,
          projectId: project.id,
          companyId: project.company_id,
          event: AnalyticsSessionEventValue.FlowStarted,
          targetId: flowId,
          query,
          hostUrl: hostInfo.current?.url,
          urlQuery: hostInfo.current?.query,
          baseUrl,
          userId: userIdRef.current,
        })
    );
  }, [
    demoPlayerEventEmitter,
    dispatchAnalyticsEvent,
    project,
    demoId,
    query,
    hostInfo,
    baseUrl,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetChangeEvent,
      ({ widgetId }) =>
        dispatchAnalyticsEvent({
          demoId,
          projectId: project.id,
          companyId: project.company_id,
          event: AnalyticsSessionEventValue.WidgetViewed,
          targetId: widgetId,
          query,
          hostUrl: hostInfo.current?.url,
          urlQuery: hostInfo.current?.query,
          baseUrl,
          userId: userIdRef.current,
        })
    );
  }, [
    demoPlayerEventEmitter,
    dispatchAnalyticsEvent,
    project,
    demoId,
    query,
    hostInfo,
    baseUrl,
  ]);

  useEffect(
    () =>
      demoPlayerEventEmitter.on(
        demoplayerEventCreators.flowlistItemClickEvent,
        ({ itemId }) => {
          const currentFlowListVisitedValue =
            sessionStorageWithFallback.getItem(flowListVisitedStorageName);
          if (currentFlowListVisitedValue) {
            const currentFlowListVisitedValueObject = JSON.parse(
              currentFlowListVisitedValue
            );
            currentFlowListVisitedValueObject[itemId] = true;
            sessionStorageWithFallback.setItem(
              flowListVisitedStorageName,
              JSON.stringify(currentFlowListVisitedValueObject)
            );

            if (
              Object.values(currentFlowListVisitedValueObject).every(
                (value) => value
              ) &&
              flowlist
            ) {
              dispatchAnalyticsEvent({
                demoId: demoId,
                projectId: project.id,
                companyId: project.company_id,
                event: AnalyticsSessionEventValue.FlowlistCompleted,
                targetId: flowlist.id,
                query,
                hostUrl: hostInfo.current?.url,
                urlQuery: hostInfo.current?.query,
                baseUrl,
                userId: userIdRef.current,
              });
            }
          }
        }
      ),
    [
      demoPlayerEventEmitter,
      dispatchAnalyticsEvent,
      project,
      demoId,
      query,
      hostInfo,
      baseUrl,
      flowlist,
      flowListVisitedStorageName,
    ]
  );

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.lastWidgetEnterEvent,
      ({ flowId }) => {
        dispatchAnalyticsEvent({
          demoId,
          projectId: project.id,
          companyId: project.company_id,
          event: AnalyticsSessionEventValue.FlowEnded,
          targetId: flowId,
          query,
          hostUrl: hostInfo.current?.url,
          urlQuery: hostInfo.current?.query,
          baseUrl,
          userId: userIdRef.current,
        });
      }
    );
  }, [
    dispatchAnalyticsEvent,
    demoPlayerEventEmitter,
    project,
    demoId,
    query,
    hostInfo,
    baseUrl,
  ]);

  useEffect(() => {
    if (project.kind === ProjectKind.Image) return;

    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.pageChangeEvent,
      (arg) => {
        dispatchAnalyticsEvent({
          demoId,
          projectId: project.id,
          companyId: project.company_id,
          event: AnalyticsSessionEventValue.PageViewed,
          targetId: arg.pageId,
          query,
          hostUrl: hostInfo.current?.url,
          urlQuery: hostInfo.current?.query,
          baseUrl,
          userId: userIdRef.current,
        });
      }
    );
  }, [
    dispatchAnalyticsEvent,
    demoPlayerEventEmitter,
    project,
    demoId,
    query,
    hostInfo,
    baseUrl,
  ]);

  const onOpenExternalUrl = useCallback(
    (url: string, widgetId?: WidgetId, projectId?: ProjectId) => {
      dispatchAnalyticsEvent({
        demoId,
        projectId: project.id,
        companyId: project.company_id,
        event: AnalyticsSessionEventValue.CtaOpened,
        targetId: widgetId || projectId,
        extUrl: url,
        query,
        hostUrl: hostInfo.current?.url,
        urlQuery: hostInfo.current?.query,
        baseUrl,
        userId: userIdRef.current,
      });
    },
    [dispatchAnalyticsEvent, project, demoId, query, hostInfo, baseUrl]
  );

  return {
    onOpenExternalUrl,
  };
}

export type UsePublicDemoHostInfoResponse = RefObject<{
  url?: string;
  query?: string;
}>;
/**
 * `usePublicDemoHostInfo` returns {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Host-application "host" application} meta data.
 *
 * "host" is:
 * – customer's webapp which embdes `/demo`;
 * – storylane `/share` page which embeds `/demo`;
 *
 * @return
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/10813441/Host+app+script#Host-url-tracking storylane.js docs}
 */
export function usePublicDemoHostInfo({
  search,
}: IGenericPublicDemoProps): UsePublicDemoHostInfoResponse {
  const hostInfo = useRef<{
    /**
     * "Host" full-url can be received only from customer's application using `postMessage`.
     * Read about {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/10813441/Host+app+script#Host-url-tracking cross-origin event}
     *
     * It is `undefined` for the `/share` page.
     */
    url: string | undefined;
    /**
     * `query` is a string which contains only customer's query-params.
     *
     * E.g. "utm_from=landing&utm_date=22.02.22".
     *
     * It can be received from {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/10813441/Host+app+script#Host-url-tracking customer's application} OR retrieved from the `/share` page query-params {@link https://storylane.atlassian.net/l/cp/UnHwEGp0 passed down} to the `/demo` page.
     * It shouldn't include `/demo` page "util" query-params.
     */
    query: string | undefined;
  } | null>(null);
  if (!hostInfo.current) {
    const publicDemoParsedQuery = parse(search);

    /**
     * Filter out query-params related to the "/demo" page.
     * We assume that only "from /share page" rest params are left.
     */
    const utilQueryParamsKeys = Object.values(DemoPageSearchParams);
    const parsedQueryWithRestParams = Object.keys(
      publicDemoParsedQuery
    ).reduce<ParsedQs>((accum, publicDemoQueryParamKey) => {
      if (
        utilQueryParamsKeys.includes(
          publicDemoQueryParamKey as unknown as DemoPageSearchParams
        )
      )
        return accum;

      accum[publicDemoQueryParamKey] =
        publicDemoParsedQuery?.[publicDemoQueryParamKey];
      return accum;
    }, {});
    hostInfo.current = {
      url: undefined,
      query: stringify(parsedQueryWithRestParams),
    };
  }

  useEffect(() => {
    const handleMessageReceive = (messageEvent: MessageEvent) => {
      try {
        if (
          messageEvent.data?.source === CrossOriginHostInfoEvent &&
          Boolean(messageEvent.data?.host)
        ) {
          hostInfo.current = {
            url: messageEvent.data.host.url as string, // e.g. 'https://www.customer-app.com/path?utm_param=1&random_param=2&email=johndoe@mail.com'
            query: messageEvent.data.url_query as string | undefined, // e.g. 'utm_param=1&random_param=2'
          };
        }
      } catch (error) {
        captureException(error);
      }
    };
    window.addEventListener('message', handleMessageReceive);

    return () => {
      window.removeEventListener('message', handleMessageReceive);
    };
  }, []);

  return hostInfo;
}

export function useIsDemoEmbedded({
  baseUrl,
  demoId,
}: {
  baseUrl: string;
  demoId: string;
}) {
  const [isDemoEmbedded, setIsDemoEmbedded] = useState<boolean>(false);

  useEffect(() => {
    try {
      const iframe = window?.top?.document?.querySelector(
        `iframe[name='${embedIframeName}']`
      );

      const dataTestId = iframe?.getAttribute('data-testid');

      const iframeSrc = iframe?.getAttribute('src');

      const isIframeIncludesDemo = iframeSrc?.includes(
        `${baseUrl}/demo/${demoId}`
      );

      setIsDemoEmbedded(dataTestId === undefined && !isIframeIncludesDemo);
    } catch (error) {
      if (
        error instanceof Error &&
        error?.message?.includes('Blocked a frame with origin')
      ) {
        setIsDemoEmbedded(true);
      }
    }
  }, [baseUrl, demoId]);

  return {
    isDemoEmbedded,
  };
}

interface UseDocumentCustomScriptsArg {
  scripts: NormalizedPublishedProject['scripts'];
}
export function useDocumentCustomScripts({
  scripts,
}: UseDocumentCustomScriptsArg) {
  const projectRootScripts = useMemo(() => {
    return scripts.filter(
      (script): script is DemoPlayerRootScript =>
        script.position === 'body' || script.position === 'head'
    );
  }, [scripts]);
  /**
   * Inject custom scripts into the document
   * @see {@link https://storylane.atlassian.net/l/cp/Rh0wdszA Docs}
   */
  useEffectOnce(() => {
    const doc = document;
    if (projectRootScripts && projectRootScripts.length) {
      projectRootScripts
        .filter(
          (script) => script.position === 'body' || script.position === 'head'
        )
        .forEach((script) => {
          injectProjectScriptsIntoDocument(doc, script);
        });
    }

    return () => {
      removeProjectScriptsFromDocument(doc);
    };
  });
}

interface UseSharePageHistoryStateArg {
  startPageId: string;
  demoPlayerEventEmitter: DemoPlayerEventEmitter;
}
export function useSharePageHistoryState(arg: UseSharePageHistoryStateArg) {
  const { startPageId, demoPlayerEventEmitter } = arg;

  // TODO: introduce helpful utils and hooks to deal with a /share page

  let sharePageWindow: Window | null = null;
  try {
    // check CORS accessibility
    window.parent.document;
    window.parent.location.href.includes('/share');

    sharePageWindow = window.parent;
  } catch (error) {
    // prevent CORS issue - not a /share page
  }

  useEffect(() => {
    const popStateHandler = (e: PopStateEvent) => {
      demoPlayerEventEmitter.emit(
        demoplayerEventCreators.requestPageChangeEvent,
        {
          pageId: e.state.page_id || startPageId,
        }
      );
    };
    sharePageWindow?.addEventListener('popstate', popStateHandler);
    return () =>
      sharePageWindow?.removeEventListener('popstate', popStateHandler);
  }, [startPageId, demoPlayerEventEmitter, sharePageWindow]);
}

interface useVendorAnalyticsProps {
  demoPlayerEventEmitter: DemoPlayerEventEmitter;
  isGAEnabled: boolean;
  isGTMEnabled: boolean;
  project: NormalizedPublishedProject;
  flows: NormalizedPublishedFlows | null;
  widgets: NormalizedPublishedWidgets | null;
  demoId: string;
  query: QueryString.ParsedQs;
  baseUrl: string;
  demoUrl: string;
}
/**
 * Communicate with a host application.
 * Host application - client's app which ebmeds our demo.
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Host-app-Message-Communication
 * @param param0
 */
export function useVendorAnalytics({
  isGAEnabled,
  isGTMEnabled,
  demoId,
  project,
  flows,
  widgets,
  demoUrl,
  demoPlayerEventEmitter,
}: useVendorAnalyticsProps): {
  onInit: () => void;
  onLeadCapture: (lead: PublicDemoLead) => void;
  onOpenExternalUrl: (
    data: { url?: string; target: ExtUrlTarget },
    widgetId?: WidgetId
  ) => void;
  onWalkthroughFinished: () => void;
  onCustomCtaExternalUrl: (
    data: { url?: string; target: ExtUrlTarget },
    widgetId?: WidgetId
  ) => void;
} {
  const demoName = project?.name || '';

  const dispatchVendorAnalyticsEvent = useCallback(
    (arg: ThirdPartyAnalyticsEventReturn) => {
      if (isGAEnabled) {
        sendGAEvent(arg);
      }
      if (isGTMEnabled) {
        // TODO: coming soon
      }
    },
    [isGAEnabled, isGTMEnabled]
  );

  const onInit = useCallback(() => {
    dispatchVendorAnalyticsEvent(
      sendProjectResolvedVACreator({
        demo_id: demoId,
        demo_name: demoName,
        demo_url: demoUrl,
      })
    );
  }, [demoId, demoName, demoUrl, dispatchVendorAnalyticsEvent]);

  useEffect(() => {
    if (project.kind === ProjectKind.Image) return;

    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.pageChangeEvent,
      (arg) => {
        const page = project?.pages.find((page) => page.id === arg.pageId);
        if (!page) {
          console.error(`Unable to find page by id: ${arg.pageId}`);
          return;
        }
        dispatchVendorAnalyticsEvent(
          sendPageChangedVACreator({
            demo_id: demoId,
            demo_name: demoName,
            demo_url: demoUrl,
            page_id: page.id,
            page_name: page.name,
          })
        );
      }
    );
  }, [
    dispatchVendorAnalyticsEvent,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
    project,
    demoId,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetChangeEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          dispatchVendorAnalyticsEvent(
            sendWidgetChangedVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flow_id: flow.id,
              flow_name: flow.name,
              step_id: widget.id,
              step_index: widgetIndex,
            })
          );
        }
      }
    );
  }, [
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    dispatchVendorAnalyticsEvent,
    demoPlayerEventEmitter,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetCtaClickEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          dispatchVendorAnalyticsEvent(
            sendWidgetCTAClickVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flow_id: flow.id,
              flow_name: flow.name,
              step_id: widget.id,
              step_index: widgetIndex,
            })
          );
        }
      }
    );
  }, [
    demoPlayerEventEmitter,
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    dispatchVendorAnalyticsEvent,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.widgetSecondaryClickEvent,
      ({ widgetId }) => {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          const widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
          dispatchVendorAnalyticsEvent(
            sendWidgetSecondaryClickVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flow_id: flow.id,
              flow_name: flow.name,
              step_id: widget.id,
              step_index: widgetIndex,
            })
          );
        }
      }
    );
  }, [
    demoPlayerEventEmitter,
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    dispatchVendorAnalyticsEvent,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.flowChangeEvent,
      ({ flowId }) => {
        const flow = flows?.[flowId];
        if (flow) {
          dispatchVendorAnalyticsEvent(
            sendFlowChangedVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flow_id: flow.id,
              flow_name: flow.name,
            })
          );
        }
      }
    );
  }, [
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
    dispatchVendorAnalyticsEvent,
  ]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.lastWidgetEnterEvent,
      ({ flowId }) => {
        const flow = flows?.[flowId];
        if (flow) {
          dispatchVendorAnalyticsEvent(
            sendFlowEndVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flow_id: flow.id,
              flow_name: flow.name,
            })
          );
        }
      }
    );
  }, [
    widgets,
    flows,
    demoId,
    demoUrl,
    demoName,
    demoPlayerEventEmitter,
    dispatchVendorAnalyticsEvent,
  ]);

  const onOpenExternalUrl = useCallback(
    (data: { url?: string; target: ExtUrlTarget }, widgetId?: WidgetId) => {
      let widgetIndex: number | undefined;
      let flowId: string | undefined;
      let flowName: string | undefined;

      if (widgetId) {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
        }
        flowName = flow?.name;
        flowId = flow?.id;
      }
      dispatchVendorAnalyticsEvent(
        sendOpenedExternalUrlVACreator({
          demo_id: demoId,
          demo_name: demoName,
          demo_url: demoUrl,
          step_id: widgetId,
          step_index: widgetIndex,
          flow_id: flowId,
          flow_name: flowName,
          ext_url: data?.url || '',
          ext_url_target: data.target,
        })
      );
    },
    [demoId, demoName, demoUrl, flows, widgets, dispatchVendorAnalyticsEvent]
  );

  const onCustomCtaExternalUrl = useCallback(
    (data: { url?: string; target: ExtUrlTarget }, widgetId?: WidgetId) => {
      let widgetIndex: number | undefined;
      let flowId: string | undefined;
      let flowName: string | undefined;

      if (widgetId) {
        const widget = widgets?.[widgetId];
        const flow = widget ? flows?.[widget.flowId] : null;
        if (widget && flow) {
          widgetIndex = flow.widgets.findIndex(
            (flowWidgetId) => flowWidgetId === widgetId
          );
        }
        flowName = flow?.name;
        flowId = flow?.id;
      }

      dispatchVendorAnalyticsEvent(
        sendCustomCtaVACreator({
          demo_id: demoId,
          demo_name: demoName,
          demo_url: demoUrl,
          step_id: widgetId,
          step_index: widgetIndex,
          flow_id: flowId,
          flow_name: flowName,
          ext_url: data?.url || '',
          ext_url_target: data.target,
        })
      );
    },
    [demoId, demoName, demoUrl, flows, widgets, dispatchVendorAnalyticsEvent]
  );

  const onLeadCapture = useCallback(
    (lead: PublicDemoLead) => {
      dispatchVendorAnalyticsEvent(
        sendLeadCapturedVACreator({
          demo_id: demoId,
          demo_name: demoName,
          demo_url: demoUrl,
          ...flattenObject(lead, '', 'lead'),
        })
      );
    },
    [demoId, demoName, demoUrl, dispatchVendorAnalyticsEvent]
  );

  useEffect(
    () =>
      demoPlayerEventEmitter.on(
        demoplayerEventCreators.flowlistItemClickEvent,
        ({ itemId }) => {
          dispatchVendorAnalyticsEvent(
            sendFlowlistItemClickVACreator({
              demo_id: demoId,
              demo_name: demoName,
              demo_url: demoUrl,
              flowlist_item_id: itemId,
            })
          );
        }
      ),
    [
      demoPlayerEventEmitter,
      demoName,
      demoUrl,
      demoId,
      dispatchVendorAnalyticsEvent,
    ]
  );

  const onWalkthroughFinished = useCallback(() => {
    dispatchVendorAnalyticsEvent(
      sendDemoFinishedVACreator({
        demo_id: demoId,
        demo_name: demoName,
        demo_url: demoUrl,
      })
    );
  }, [demoId, demoName, demoUrl, dispatchVendorAnalyticsEvent]);

  return {
    onInit,
    onLeadCapture,
    onOpenExternalUrl,
    onWalkthroughFinished,
    onCustomCtaExternalUrl,
  };
}
