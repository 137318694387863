import { useCallback, useContext, useMemo } from 'react';
import { FlowId } from 'shared/src/features/flow/flow.types';
import { FlowlistItem } from 'shared/src/features/flowlist/flowlist.types';
import { FlowlistItemTargetKind } from 'shared/src/features/flowlist/flowlist.constants';

import Flowlist from './Flowlist';
import { DemoPlayerProps } from '../DemoPlayer';
import { useFlowlistPlayerState } from '../../hooks/useFlowlistPlayerState';
import { DemoPlayerFlowlistFlowProgress } from '../../types';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import { demoplayerEventCreators } from '../../events/eventCreators';

type FlowlistManagerProps = {
  onOpenExtUrl?: DemoPlayerProps['onOpenExtUrl'];
};

function FlowlistManager({
  onOpenExtUrl,
}: FlowlistManagerProps): JSX.Element | null {
  const {
    checklist,
    checklistProgress,
    flowProgress,
    flowsDict,
    activeItem,
    checklistIsMinimized,
    minimizeChecklist,
    startChecklistItem,
    shouldAddWatermark,
    replayFlowFromCheckList,
  } = useFlowlistPlayerState();
  const { emitter } = useContext(DemoPlayerContext);

  const handleCloseClick = useCallback(() => {
    minimizeChecklist();
  }, [minimizeChecklist]);

  const handleItemClick = useCallback(
    (item: FlowlistItem) => {
      emitter.emit(demoplayerEventCreators.flowlistItemClickEvent, {
        itemId: item.id,
      });
      startChecklistItem(item.id, onOpenExtUrl);
    },
    [startChecklistItem, onOpenExtUrl, emitter]
  );

  const flowProgressDict = useMemo(() => {
    return flowProgress.reduce<Record<FlowId, number>>((result, fProgress) => {
      result[fProgress.id] = fProgress.widgets.filter((widgetId) =>
        Boolean(flowsDict[fProgress.id]?.widgets.includes(widgetId))
      ).length;
      return result;
    }, {});
  }, [flowProgress, flowsDict]);

  const flowlistProgressDict = useMemo(() => {
    return (
      checklist?.items.reduce<DemoPlayerFlowlistFlowProgress>(
        (result, checklistItem) => {
          if (
            checklistItem.target_kind === FlowlistItemTargetKind.Flow &&
            checklistItem.target_value
          ) {
            result[checklistItem.target_value] = {
              current: flowProgressDict[checklistItem.target_value] || 0,
              total: flowsDict[checklistItem.target_value]?.widgets.length || 0,
            };
          }
          return result;
        },
        {}
      ) ?? {}
    );
  }, [checklist, flowProgressDict, flowsDict]);

  if (!checklist) {
    throw new Error(
      `Unable to render Flowlist. Reason: checklist === ${checklist}`
    );
  }
  const onReplayClickHandler = (item: FlowlistItem) => {
    replayFlowFromCheckList(item.target_value);
  };

  return (
    <Flowlist
      flowlist={checklist}
      progress={checklistProgress}
      flowlistFlowProgress={flowlistProgressDict}
      isMinimized={checklistIsMinimized}
      onClose={handleCloseClick}
      onItemClick={handleItemClick}
      activeItem={activeItem}
      shouldAddWatermark={shouldAddWatermark}
      onReplayClickHandler={onReplayClickHandler}
    />
  );
}

export default FlowlistManager;
