import { FC, useEffect, useMemo, useRef, useState } from 'react';

import VideoContext from './video.context';

type VideoContextProviderProps = {
  defaultValues?: Partial<{
    isPlaying: boolean;
    isMuted: boolean;
    currentTime: number;
    playbackRate: number;
    isHlsVideo: boolean;
  }>;
};

const VideoContextProvider: FC<VideoContextProviderProps> = ({
  defaultValues,
  children,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isPlaying, setIsPlaying] = useState(defaultValues?.isPlaying || false);
  const [isMuted, setIsMuted] = useState<boolean>(
    typeof defaultValues?.isMuted === 'undefined'
      ? true
      : defaultValues?.isMuted
  );
  const [currentTime, setCurrentTime] = useState(
    defaultValues?.currentTime || 0
  );

  const currentTimeRef = useRef<number>(currentTime);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  const [playbackRate, setPlaybackRate] = useState(
    defaultValues?.playbackRate || 1
  );

  const [duration, setDuration] = useState(0);

  const [hasAudio, setHasAudio] = useState(false);

  const values = useMemo(
    () => ({
      isMuted,
      setIsMuted,
      isPlaying,
      setIsPlaying,
      currentTime,
      currentTimeRef,
      setCurrentTime,
      playbackRate,
      setPlaybackRate,
      isHlsVideo: Boolean(defaultValues?.isHlsVideo),
      duration,
      setDuration,
      hasAudio,
      setHasAudio,
      videoRef,
    }),
    [
      currentTime,
      defaultValues?.isHlsVideo,
      duration,
      hasAudio,
      isMuted,
      isPlaying,
      playbackRate,
    ]
  );

  return (
    <VideoContext.Provider value={values}>{children}</VideoContext.Provider>
  );
};

export default VideoContextProvider;
