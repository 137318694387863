import { RefObject, VFC } from 'react';
import WidgetText from 'shared/src/components/widgets/WidgetText';
import { WithSecondary } from 'shared/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isLeadCaptureLikeWidget,
  isSecondaryButtonEnabled,
  isVideoClip,
} from 'shared/src/features/widgets/widgets.utils';

import { DemoPlayerWidget } from '../../../types';
import WidgetBottomTrayNavigation from '../WidgetBottomTrayNavigation/WidgetBottomTrayNavigation';
import styles from './WidgetBottomTrayBody.module.css';

const WidgetBottomTrayBody: VFC<{
  widget: DemoPlayerWidget;
  hideShadow: boolean;
  showFlowlistLaunch: boolean;
  onCta: () => void;
  onPrev: () => void;
  onSecondary: () => void;
  contentRef: RefObject<HTMLDivElement>;
  ctaRef: RefObject<HTMLButtonElement>;
  prevRef: RefObject<HTMLButtonElement>;
  secondaryRef: RefObject<HTMLButtonElement>;
}> = (props) => {
  const {
    widget,
    hideShadow,
    onPrev,
    onCta,
    onSecondary,
    contentRef,
    secondaryRef,
    prevRef,
    ctaRef,
    showFlowlistLaunch,
  } = props;

  const showDescription = isAnchorBasedWidget(widget);
  const secondaryTextValue =
    (widget.options as Partial<WithSecondary>).secondary?.value || '';
  const showSecondary =
    isSecondaryButtonEnabled(widget) &&
    Boolean(widget.options.secondary?.value);
  const showPrev = widget.options.root?.prev_button && Boolean(widget._prevId);
  const showCta = !isLeadCaptureLikeWidget(widget) && !isVideoClip(widget);
  return (
    <>
      <div
        className={styles.content}
        data-flowlist={showFlowlistLaunch}
        ref={contentRef}
      >
        {showDescription && (
          <WidgetText
            value={widget.options.description.value}
            className={styles.text}
          />
        )}

        <div className={styles.contentActions}>
          {showSecondary && (
            <button
              ref={secondaryRef}
              className={styles.secondaryBtn}
              onClick={onSecondary}
            >
              {secondaryTextValue}
            </button>
          )}
        </div>
      </div>
      <WidgetBottomTrayNavigation
        totalStepsCount={widget._totalStepsCount}
        currentStepNumber={widget._currentStepNumber}
        showCta={showCta}
        showPrev={showPrev}
        onCta={onCta}
        onPrev={onPrev}
        ctaRef={ctaRef}
        hideShadow={hideShadow}
        prevRef={prevRef}
      />
    </>
  );
};

export default WidgetBottomTrayBody;
