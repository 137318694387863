import { ProjectPageId } from 'shared/src/features/projectPages/projectPages.types';
import { RendererImagePageToFileMapItem } from 'shared/src/features/renderer/renderer.types';
import { VisitedSubstitutionsDict } from 'shared/src/features/substitution/substitution.utils';
import { typewriterSpeedAttribute } from 'shared/src/features/typewriter/typewriter.constants';
import { noParentElementTypewriter } from 'shared/src/features/typewriter/typewriter.utils';

import { DemoPlayerTextSubstitutionsDict } from '../../types';

const MAX_FETCH_COUNT = 6;
interface DemoPlayerHtmlManagerProps {
  /**
   * Collection of page identificators defines preload sequence.
   */
  queue: ProjectPageId[];
  /**
   * Page to file-url hash map
   */
  pages: Record<ProjectPageId, RendererImagePageToFileMapItem>;
  /**
   * Hashmap of values which should be replaced in html string.
   * {
   *  "{{key}}": value
   * }
   *
   */
  textSubstitutions: DemoPlayerTextSubstitutionsDict;
  /**
   * Callback is invoked every time html data changes
   */
  onChange: () => void;
  isPreview?: boolean;
}
export class DemoPlayerPageHtmlManager {
  protected fetch: ProjectPageId[] = [];
  protected queue;
  protected result: Record<
    ProjectPageId,
    {
      html: string;
      substitutions: VisitedSubstitutionsDict;
    }
  > = {};
  protected pages;
  /**
   * Collection of pages under parsing
   */
  private parse: ProjectPageId[] = [];
  private textSubstitutions: DemoPlayerTextSubstitutionsDict = {};
  private onChange;
  private isPreview;

  constructor({
    textSubstitutions,
    onChange,
    isPreview = false,
    queue,
    pages,
  }: DemoPlayerHtmlManagerProps) {
    this.queue = queue;
    this.pages = pages;
    this.textSubstitutions = textSubstitutions;
    this.isPreview = isPreview;
    this.onChange = onChange;
    this.init();
  }

  /**
   * Intialize pages download process starting with first `MAX_FETCH_COUNT` pages.
   */
  private async init() {
    if (this.queue.length > 0) {
      const pageIds = this.queue.slice(0, MAX_FETCH_COUNT - 1);

      for (const pageId of pageIds) {
        this.retrieveAndProcessPage(pageId);
      }
    }
  }

  private async parseHtml(html: string) {
    return new Promise<{
      html: string;
      substitutions: VisitedSubstitutionsDict;
    }>((resolve) => {
      /**
       * @see {@link https://v3.vitejs.dev/guide/features.html#import-with-constructors Vite usage}
       */
      const worker = new Worker(
        new URL('./DemoPlayerRendererHtml.workers.ts', import.meta.url),
        {
          type: 'module',
        }
      );
      worker.postMessage({
        html,
        substitutionsMap: this.textSubstitutions,
        replaceEmptyValuesWithHtmlComment: !this.isPreview,
        addHtmlBreadcrumb: !this.isPreview,
      });

      worker.onmessage = ({
        data: { html, substitutions },
      }: {
        data: { html: string; substitutions: VisitedSubstitutionsDict };
      }) => {
        resolve({ html, substitutions });
        worker.terminate();
      };
    });
  }

  private async fetchHtml(page: RendererImagePageToFileMapItem) {
    const [response] = await Promise.allSettled([
      fetch(page.url),
      page.bgUrl ? fetch(page.bgUrl) : Promise.resolve(),
    ]);

    if (response.status === 'rejected') {
      throw new Error(response.reason);
    }

    return await response.value.text();
  }

  /**
   * When page processing is done, then start first one from the queue.
   */
  private onPageDone(): void {
    if (this.queue.length > 0) {
      this.retrieveAndProcessPage(this.queue[0]);
    }
    this.onChange();
  }

  protected async retrieveAndProcessPage(pageId: ProjectPageId): Promise<void> {
    try {
      // remove "page id" from a queue
      this.queue = this.queue.filter((qPageId) => qPageId !== pageId);

      // fetch html from S3
      this.fetch.push(pageId);
      const html = await this.fetchHtml(this.pages[pageId]);
      this.fetch = this.fetch.filter((fetchPageId) => fetchPageId !== pageId);

      // parse html
      this.parse.push(pageId);
      const parsedHtml = await this.parseHtml(html);
      this.parse = this.parse.filter((parsePageId) => parsePageId !== pageId);

      this.result[pageId] = parsedHtml;

      this.onPageDone();
    } catch (error) {
      console.error(error);
    }
  }

  protected isPageInProgress(pageId: ProjectPageId): boolean {
    return [...this.fetch, ...this.parse].includes(pageId);
  }

  public updateTextSubstitutions(
    textSubstitutions: DemoPlayerTextSubstitutionsDict
  ): void {
    this.textSubstitutions = textSubstitutions;
  }

  /**
   * If particular page is requested AND we are NOT processing it(fecth|parse), then start processing immediately.
   * @param pageId
   */
  public requestPageData(
    pageId: string
  ): { html: string; substitutions: VisitedSubstitutionsDict } | null {
    const pageData = this.result[pageId];

    if (!pageData) {
      if (!this.isPageInProgress(pageId)) {
        this.retrieveAndProcessPage(pageId);
      }
      return null;
    }

    return { html: pageData.html, substitutions: pageData.substitutions };
  }
}

export function hideTypewriterElements(loadedDocument: Document): Document {
  const observeElements = loadedDocument.querySelectorAll<HTMLElement>(
    `[${typewriterSpeedAttribute}]`
  );
  if (observeElements.length) {
    const filteredObserveElements = [...observeElements].filter(
      noParentElementTypewriter
    );
    for (const e of filteredObserveElements) {
      const fullHtml = e.innerHTML ?? '';
      e.innerHTML = '';
      e.dataset.fullHtml = fullHtml;
    }
  }

  return loadedDocument;
}
