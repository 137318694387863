import { isLeadCaptureLikeWidget } from 'shared/src/features/widgets/widgets.utils';
import { useHotkeys } from 'react-hotkeys-hook';

import { useWidgetPlayerState } from './useWidgetPlayerState';

export function useDemoWidgetHotkeys() {
  const { widget, goNextWidget, goPrevWidget, isPreview } =
    useWidgetPlayerState();

  useHotkeys(
    'ArrowRight',
    goNextWidget,
    {
      enabled: isPreview || (widget ? !isLeadCaptureLikeWidget(widget) : false),
    },
    [goNextWidget, widget]
  );
  useHotkeys('ArrowLeft', goPrevWidget, { enabled: Boolean(widget?._prevId) });
}
