import {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import cx from 'classnames';

import { ProjectPageKind } from '../../../features/projectPages/projectPages.constants';
import styles from './RendererImage.module.css';
import { RendererImagePageToFileMapItem } from '../../../features/renderer/renderer.types';
import { isImageElement } from '../../../utils/dom';
import Skeleton from '../../../uikit/Skeleton';

export interface RendererImageClickCoords {
  x: number;
  y: number;
}

export type RendererImageRenderFnType = (arg: {
  imageValue: RendererImagePageToFileMapItem;
  isMatchingUrl: boolean;
  isLoadingCompleted: boolean;
  isMatchingVideoWidgetId: boolean;
}) => ReactNode;

interface RendererImageProps {
  /**
   * `value` is a page needs to be rendered.
   */
  value: RendererImagePageToFileMapItem | undefined;
  /**
   * `allValues` should contain all images which gonna be rendered.
   * Only one image/video is rendered at a time(see `value`).
   * Others will be hidden using css-rule(opacity) — preload mechanism.
   */
  allValues: RendererImagePageToFileMapItem[];
  refCB?: (node: HTMLDivElement) => void;
  onReady?: (isReadyFlag: boolean) => void;
  isPointerEventsDisabled?: boolean;
  renderFn: RendererImageRenderFnType;
  style?: CSSProperties;
}

type CompleteMediaMap = { [mediaSrc: string]: boolean };

const RendererImage: VFC<RendererImageProps> = ({
  value,
  allValues,
  refCB,
  onReady,
  isPointerEventsDisabled,
  renderFn,
  style,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const [completeMedia, setCompleteMedia] = useState<CompleteMediaMap>(
    () => ({})
  );

  // Setting all videos as loaded without any condition. Maybe we can listen to some event in the future
  useEffect(() => {
    setCompleteMedia((prevState) => {
      const nextState = { ...prevState };

      for (const val of allValues) {
        if (val.type === 'video') {
          nextState[val.url] = true;
        }
      }

      return nextState;
    });
  }, [allValues]);

  const isCurrentValueReady = value ? Boolean(completeMedia[value.url]) : false;

  useEffect(() => {
    onReady?.(isCurrentValueReady);
  }, [onReady, isCurrentValueReady]);

  useEffect(() => {
    /**
     * Handle `<img />` `onload` event.
     * Update `loadedImgs[value]` when image data is loaded
     */
    let imageElement = rootRef.current;

    if (imageElement) {
      const listener = (e: Event) => {
        const imageElement = e.target as HTMLDivElement;
        if (isImageElement(imageElement)) {
          setCompleteMedia((prevState) => ({
            ...prevState,
            [imageElement.src]: imageElement.complete,
          }));
        }
      };

      imageElement.addEventListener('load', listener, true);

      return () => {
        if (imageElement) {
          imageElement.removeEventListener('load', listener, true);
          imageElement = null;
        }
      };
    }
  }, [onReady]);

  /**
   * Process `allValues` to improve preloading:
   *  1. `value` should always go first as the only visible image.
   *  2. limit number of images rendered per time by `MAX_FETCH_COUNT`.
   *     Increase this value by complete images count.
   */
  const completeMediaSrc = useMemo(() => {
    return Object.keys(completeMedia).filter(
      (imageSrc) => completeMedia[imageSrc]
    );
  }, [completeMedia]);

  const remainingMediaSrc = useMemo(() => {
    const MAX_FETCH_COUNT = 6; // browser maximum connections to the same origin
    return allValues
      .filter((v) => !completeMedia[v.url])
      .slice(0, MAX_FETCH_COUNT - 1)
      .map((v) => v.url);
  }, [completeMedia, allValues]);

  const images = useMemo(() => {
    return allValues.filter(
      (v) =>
        completeMediaSrc.includes(v.url) || remainingMediaSrc.includes(v.url)
    );
  }, [remainingMediaSrc, completeMediaSrc, allValues]);

  return (
    <div
      ref={(node) => {
        refCB && refCB(node as HTMLDivElement);
        rootRef.current = node as HTMLDivElement;
      }}
      className={cx(styles.root, {
        [styles.rootDisablePointerEvents]: isPointerEventsDisabled,
      })}
      style={style}
    >
      {images.map((imageValue) => {
        const isMatchingUrl = imageValue.url === value?.url;
        const isLoadingCompleted = completeMedia[imageValue.url];
        const isMatchingVideoWidgetId =
          imageValue.videoWidgetId === value?.videoWidgetId;

        if (imageValue.type === 'video') {
          if (
            !imageValue.videoWidgetId ||
            (value?.type === ProjectPageKind.Video && !value.videoWidgetId)
          ) {
            console.error('Missing mandatory widgetId for video clip');
          }
        }

        return renderFn({
          imageValue,
          isMatchingUrl,
          isLoadingCompleted,
          isMatchingVideoWidgetId,
        });
      })}
      <Skeleton
        className={cx(styles.skeletonStatic, {
          [styles.skeletonStaticTop]: !isCurrentValueReady,
        })}
      />
    </div>
  );
};

export default RendererImage;
