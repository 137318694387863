import { CSSProperties, FC } from 'react';
import cx from 'classnames';
import Tooltip from 'shared/src/components/Tooltip';

import styles from './LaunchButton.module.css';
import { ActiveFlowlistItem } from '../../../hooks/useFlowlistPlayerState';
import { DemoPlayerFlowlist } from '../../../types';

interface LaunchButtonProps {
  data: DemoPlayerFlowlist['options']['launch'];
  onClick: () => void;
  activeItem: ActiveFlowlistItem | null;
  isResponsive: boolean;
  tooltipDisabled?: boolean;
}

const LaunchButton: FC<LaunchButtonProps> = (props) => {
  const { data, activeItem, onClick, isResponsive, tooltipDisabled } = props;
  return (
    <div
      className={cx(styles.root, styles[data.position], {
        [styles.rootResponsive]: isResponsive,
      })}
      style={
        {
          '--offsetX': `${data.offsetX || 0}%`,
          '--offsetY': `${data.offsetY || 0}%`,
        } as CSSProperties
      }
      data-testid="flowlist-launch"
    >
      <Tooltip
        disabled={!activeItem || tooltipDisabled}
        placement="top"
        triggerOn="hover"
        offsetY={10}
      >
        <Tooltip.Trigger>
          <button
            type="button"
            onClick={onClick}
            className={styles.button}
            style={{
              backgroundColor: data.backgroundColor,
              color: data.color,
            }}
          >
            {data.value}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Content
          variant="dark"
          innerClassName={styles.tooltipContentInner}
        >
          <span className={styles.tooltipContent}>
            {activeItem && activeItem.name}
          </span>
        </Tooltip.Content>
      </Tooltip>
      {activeItem && (
        <>
          <div className={styles.activeItemStatusContainer}>
            <span
              className={styles.activeItemStatus}
              style={{
                color: data.backgroundColor,
                backgroundColor: data.color,
              }}
              data-testid="flowlist-launch-active-item-status"
            >
              {activeItem.position}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default LaunchButton;
