import { createPortal } from 'react-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PageLinkTrigger } from 'shared/src/features/pageLink/pageLink.constants';
import { PageLinkWithId } from 'shared/src/features/pageLink/pageLink.types';
import { isInternalPageLink } from 'shared/src/features/pageLink/pageLink.util';
import useLinkedElements from 'shared/src/hooks/useLinkedElements';

import HintsLayer from './HintsLayer';
import ResumeButton from '../ResumeButton';
import { DemoPlayerProps } from '../DemoPlayer/DemoPlayer';
import ReplayButton from '../ReplayButton';
import styles from './InteractiveManagerHtml.module.css';
import { useInteractivePlayerState } from '../../hooks/useInteractivePlayerState';
import CenterElementWithOverlay from '../CenterElementWithOverlay';
import {
  usePageLinkInputChangeHandler,
  usePageLinkClickHandler,
  usePageLinkScrollIntoView,
} from './InteractiveManagerHtml.hooks';
import InteractiveToast from '../InteractiveToast';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';

interface InteractiveManagerProps {
  isRendererReady: boolean;
  onOpenExtUrl?: DemoPlayerProps['onOpenExtUrl'];
}
/**
 * Only available for HTML-based projects
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Interactive-tour Interactive Tour Docs}
 */
function InteractiveManager(props: InteractiveManagerProps): JSX.Element {
  const { isRendererReady, onOpenExtUrl } = props;
  const {
    rendererElement,
    currentPageId,
    onResumeClick,
    onReplayClick,
    replayButtonConfig,
    resumeButtonConfig,
    goToPage,
    isReplayEnabled,
    isResumeEnabled,
    enabledMissclickHints,
    enabledHoverHints,
    pageLinksDict,
    widgetSlotElement,
  } = useInteractivePlayerState();

  const [hoveredLink, setHoveredLink] = useState<PageLinkWithId | undefined>(
    undefined
  );

  const { isResponsiveEnabled, playerConfig, isShadowDomEnabled } =
    useContext(DemoPlayerContext);
  const toastEnabled = Boolean(
    playerConfig.page_link_hints && playerConfig.page_link_toast_text
  );

  const rendererIframeElement = rendererElement as HTMLIFrameElement | null;

  const { linkedElements } = useLinkedElements({
    pageId: currentPageId,
    rendererElement: isRendererReady ? rendererIframeElement : null,
    links: pageLinksDict,
    isSelfTargetInternalPageLinkDisabled: true,
    debounceDelay: 0,
  });

  const clickLinkedElementsRef = useRef<Map<Element, PageLinkWithId>>(
    new Map()
  );
  const inputLinkedElementsRef = useRef<Map<Element, PageLinkWithId>>(
    new Map()
  );
  useEffect(() => {
    const clickPageLinks: Map<Element, PageLinkWithId> = new Map();
    const inputPageLinks: Map<Element, PageLinkWithId> = new Map();
    linkedElements.forEach((pageLink, element) => {
      if (pageLink.options?.trigger === PageLinkTrigger.InputText) {
        inputPageLinks.set(element, pageLink);
      } else {
        clickPageLinks.set(element, pageLink);
      }
      if (enabledHoverHints) {
        element.addEventListener('pointerenter', () =>
          setHoveredLink(pageLink)
        );
        element.addEventListener('pointerleave', () =>
          setHoveredLink(undefined)
        );
      }
    });
    clickLinkedElementsRef.current = clickPageLinks;
    inputLinkedElementsRef.current = inputPageLinks;
  }, [linkedElements, enabledHoverHints, setHoveredLink]);

  // don't forget there is also a back/forward browser buttons
  const { onPageLinkElementInteraction } = usePageLinkScrollIntoView({
    rendererElement: isRendererReady ? rendererIframeElement : null,
  });

  const handlePageLinkInteraction = useCallback(
    (pageLink: PageLinkWithId, element: Element) => {
      if (isInternalPageLink(pageLink)) {
        onPageLinkElementInteraction(pageLink, element);
        goToPage(pageLink.target);
        try {
          window.top?.postMessage({
            message: 'storylane-page-change-event',
            payload: {
              pageId: pageLink.target,
            },
          });
        } catch (error) {
          // TODO: https://storylane.atlassian.net/browse/STORY-3301
        }
      } else if (onOpenExtUrl) {
        onOpenExtUrl({
          url: pageLink.target,
          target: pageLink?.options?.isBlank ? '_blank' : '_self',
        });
      }

      if (enabledHoverHints) {
        setHoveredLink(undefined);
      }
    },
    [
      onOpenExtUrl,
      goToPage,
      onPageLinkElementInteraction,
      enabledHoverHints,
      setHoveredLink,
    ]
  );

  usePageLinkClickHandler({
    rendererElement: isRendererReady ? rendererIframeElement : null,
    clickLinkedElementsRef,
    onPageLinkElementClick: handlePageLinkInteraction,
    isShadowDomEnabled,
  });

  usePageLinkInputChangeHandler({
    rendererElement: isRendererReady ? rendererIframeElement : null,
    inputLinkedElementsRef,
    onPageLinkElementInputChange: handlePageLinkInteraction,
    isShadowDomEnabled,
  });

  return (
    <>
      {(enabledMissclickHints || enabledHoverHints) &&
        isRendererReady &&
        rendererIframeElement &&
        widgetSlotElement &&
        createPortal(
          <HintsLayer
            linkedElements={linkedElements}
            renderer={rendererIframeElement}
            isMissClick={enabledMissclickHints}
            isHover={enabledHoverHints}
            hoveredLink={hoveredLink}
          />,
          widgetSlotElement
        )}

      {isResumeEnabled && (
        <div className={styles.buttonPosition}>
          <ResumeButton
            config={resumeButtonConfig}
            onClick={onResumeClick}
            isResponsive={isResponsiveEnabled}
            isHtmlProject
          />
        </div>
      )}

      <CenterElementWithOverlay enabled={isReplayEnabled}>
        <ReplayButton
          config={replayButtonConfig}
          onClick={onReplayClick}
          isResponsive={isResponsiveEnabled}
          isHtmlProject
        />
      </CenterElementWithOverlay>

      {toastEnabled && (
        <InteractiveToast
          isRendererReady={isRendererReady}
          clickLinkedElementsRef={clickLinkedElementsRef}
        />
      )}
    </>
  );
}

export default InteractiveManager;
