import { isInputElement } from 'shared/src/utils/isInputElement';
import { isTextAreaElement } from 'shared/src/utils/isTextAreaElement';
import debounce from 'lodash-es/debounce';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  PageLinkInternal,
  PageLinkWithId,
} from 'shared/src/features/pageLink/pageLink.types';
import { useRendererHtmlListener } from 'shared/src/features/renderer/renderer.hooks';
import { IframeEventsManagerListeners } from 'shared/src/utils/IframeEventsManager';
import { widgetShadowContainerId } from 'shared/src/features/renderer/renderer.constants';
import { getElementByUniqueSelector } from 'shared/src/utils/unique-selector';
import scrollIntoView from 'shared/src/utils/scroller';
import { getNestedBoundingClientRect } from 'shared/src/utils/dom';

interface usePageLinkInputChangeHandlerArg {
  rendererElement: HTMLIFrameElement | null;
  inputLinkedElementsRef: MutableRefObject<Map<Element, PageLinkWithId>>;
  onPageLinkElementInputChange: (
    pageLink: PageLinkWithId,
    pageLinkElement: Element
  ) => void;
  isShadowDomEnabled: boolean;
}

export function usePageLinkInputChangeHandler(
  arg: usePageLinkInputChangeHandlerArg
) {
  const {
    inputLinkedElementsRef,
    onPageLinkElementInputChange,
    rendererElement,
    isShadowDomEnabled,
  } = arg;
  const inputChangeListeners: IframeEventsManagerListeners = useMemo(() => {
    return [
      [
        'input',
        debounce((event: InputEvent) => {
          try {
            const target = event.target as unknown as Element;
            if (isInputElement(target) || isTextAreaElement(target)) {
              for (const [
                lEl,
                pageLink,
              ] of inputLinkedElementsRef.current.entries()) {
                const isTextMatched =
                  pageLink.options?.inputText === '' ||
                  pageLink.options?.inputText === target.value;

                const isTargetRelevant =
                  target.value.length > 0 &&
                  (lEl === target || lEl.contains(target));

                if (isTextMatched && isTargetRelevant) {
                  onPageLinkElementInputChange(pageLink, lEl);
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 600),
        {
          attachToShadowDom: isShadowDomEnabled, // TODO: feature is limited when there're web-components(shadow-dom)
        },
      ],
    ];
  }, [
    onPageLinkElementInputChange,
    inputLinkedElementsRef,
    isShadowDomEnabled,
  ]);

  useRendererHtmlListener({
    rendererElement,
    listeners: inputChangeListeners,
  });
}

interface UsePageLinkClickHandlerArg {
  rendererElement: HTMLIFrameElement | null;
  clickLinkedElementsRef: MutableRefObject<Map<Element, PageLinkWithId>>;
  onPageLinkElementClick: (pageLink: PageLinkWithId, element: Element) => void;
  isShadowDomEnabled: boolean;
}
export function usePageLinkClickHandler(arg: UsePageLinkClickHandlerArg) {
  const {
    clickLinkedElementsRef,
    onPageLinkElementClick,
    rendererElement,
    isShadowDomEnabled,
  } = arg;

  const clickListers = useMemo<IframeEventsManagerListeners>(() => {
    return [
      [
        'click',
        (event: MouseEvent) => {
          try {
            const clickedTarget = (event.composedPath()[0] ??
              event.target) as Element;

            /**
             * Do nothing if it is util element click
             */
            if (clickedTarget.id === widgetShadowContainerId) return;

            /**
             * Find suitable page link
             */
            for (const [
              lEl,
              pageLink,
            ] of clickLinkedElementsRef.current.entries()) {
              if (lEl === clickedTarget || lEl.contains(clickedTarget)) {
                return onPageLinkElementClick(pageLink, lEl);
              }
            }
          } catch (error) {
            console.error(error);
          }
        },
        {
          attachToShadowDom: isShadowDomEnabled,
        },
      ],
    ];
  }, [onPageLinkElementClick, clickLinkedElementsRef, isShadowDomEnabled]);

  useRendererHtmlListener({
    rendererElement,
    listeners: clickListers,
  });
}

interface UsePageLinkScrollIntoViewArg {
  rendererElement: HTMLIFrameElement | null;
}
export function usePageLinkScrollIntoView(arg: UsePageLinkScrollIntoViewArg) {
  const { rendererElement } = arg;
  const [prevPageLink, setPrevPageLink] = useState<{
    value: PageLinkInternal;
    rect: DOMRect;
  } | null>(null);
  useEffect(() => {
    const rendererDocument = rendererElement?.contentDocument;
    const rendererWindow = rendererElement?.contentWindow;
    if (
      !rendererDocument ||
      !rendererWindow ||
      !prevPageLink ||
      !prevPageLink.value.options?.persistScroll
    )
      return;

    const element = getElementByUniqueSelector(
      prevPageLink.value.selector,
      rendererDocument
    );
    if (element) {
      const currentRect = getNestedBoundingClientRect(element, rendererWindow);
      if (
        prevPageLink.rect.top - currentRect.top === 0 &&
        prevPageLink.rect.left - currentRect.left === 0
      )
        return;
      scrollIntoView({
        boundaryDocument: (rendererElement as HTMLIFrameElement)
          .contentDocument as Document,
        isAnimated: false,
        targetElement: element,
        innerOffset: {
          x: 0,
          y: 0,
        },
        viewportPosition: {
          x: prevPageLink.rect.left,
          y: prevPageLink.rect.top,
        },
      });
    }
    setPrevPageLink(null);
  }, [prevPageLink, rendererElement]);

  const handlePageLinkElement = useCallback(
    (pageLink: PageLinkInternal, pageLinkElement: Element) => {
      const rendererDocument = rendererElement?.contentDocument;
      const rendererWindow = rendererElement?.contentWindow;
      if (!rendererDocument || !rendererWindow) {
        return;
      }

      if (!pageLink.options?.persistScroll) {
        rendererDocument?.defaultView?.scrollTo(0, 0);
        return;
      }

      setPrevPageLink({
        value: pageLink,
        rect: getNestedBoundingClientRect(pageLinkElement, rendererWindow),
      });
    },
    [rendererElement]
  );

  return {
    onPageLinkElementInteraction: handlePageLinkElement,
  };
}
