import { useMemo } from 'react';
import { TooltipWidget } from 'shared/src/features/widgets/widgets.types';
import WidgetBody from 'shared/src/components/widgets/WidgetBody/WidgetBody';
import {
  calculateWidgetTextColor,
  getWidgetArrowPlacement,
  retrieveWidthStyles,
} from 'shared/src/features/widgets/widgets.utils';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import WidgetFooter from 'shared/src/components/widgets/WidgetFooter';
import WidgetText from 'shared/src/components/widgets/WidgetText';
import WidgetTooltipLayout from 'shared/src/components/widgets/WidgetTooltipLayout';

import { WidgetComponentProps } from '../Widget';
import { DemoPlayerArrowWidgetId } from '../../../constants';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Tooltip({
  widget,
  onCta,
  isMounted,
  onExit,
  onReady,
  onPrev,
  onDismiss,
  onSecondary,
  animationDelayMs,
  durationIn,
  durationOut,
  isDemoPlayerSmallScreen,
}: WidgetComponentProps<TooltipWidget>) {
  const { options } = widget;
  const autoAlignment = widget.autoAlignment;

  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth, widget.options.root.maxFrameWidth]
  );
  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn={getWidgetArrowPlacement(autoAlignment)}
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetTooltipLayout
        showEmptyDiv={isDemoPlayerSmallScreen}
        alignment={autoAlignment}
      >
        {!isDemoPlayerSmallScreen && (
          <WidgetBody
            id={DemoPlayerArrowWidgetId}
            testId={`widget_${widget.kind}_${widget.id}`}
            onDismiss={onDismiss}
            widget={widget}
            autoAlignment={autoAlignment}
            {...widthStyles}
          >
            <WidgetText
              value={options.description.value}
              style={{
                color: calculateWidgetTextColor(
                  widgetBackgroundColor,
                  'primary'
                ),
              }}
            />
            <WidgetFooter
              totalStepsNumber={widget._totalStepsCount}
              currentStepNumber={widget._currentStepNumber}
              widget={widget}
              onSecondary={onSecondary}
              onPrev={onPrev}
              onCta={onCta}
              showPrevButton={isWidgetPreviousButtonEnabled(widget)}
              id={`widget_footer_${widget.id}`}
            />
          </WidgetBody>
        )}
      </WidgetTooltipLayout>
    </WidgetAnimation>
  );
}

export default Tooltip;
