import { BaseWidget } from 'shared/src/features/widgets/widgets.types';
import { isHotspot } from 'shared/src/features/widgets/widgets.utils';

/**
 * Retrieve primary color from a widget part.
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/83001365/DRAFT+Responsive#Primary-color How to retrieve primary color}
 * @param widget
 * @returns
 */
export const retrievePrimaryColorFromWidget = (widget: BaseWidget) => {
  let resultColor = widget.options.root?.backgroundColor;
  if (isHotspot(widget)) {
    resultColor = widget.options.root.beaconColor;
  } else if (widget.options?.cta?.backgroundColor) {
    resultColor = widget.options.cta.backgroundColor.hex;
  }

  return resultColor;
};
