import { createContext, MutableRefObject } from 'react';
import { InterpreterFrom } from 'xstate';
import { ProjectKind } from 'shared/src/features/project/project.constants';
import {
  ProjectId,
  DisplayConfigType,
} from 'shared/src/features/project/project.types';
import { PageId } from 'shared/src/features/projectPages/projectPages.types';
import { WhitelabelLoader } from 'shared/src/features/whitelabel/whitelabel.constants';
import { RendererImagePageToFileMapItem } from 'shared/src/features/renderer/renderer.types';

import { DemoPlayerMachineType } from '../machine/machine';
import {
  DemoPlayerProject,
  DemoPlayerTextSubstitutionsDict,
  DemoPlayerImageSubstitutionsDict,
  DemoPlayerIframeScript,
  DemoPlayerProjectPage,
  HostInfoRef,
} from '../types';
import DemoPlayerEventEmitter from '../events/emitter';

export interface DemoPlayerContextValue {
  demoPlayerService: InterpreterFrom<DemoPlayerMachineType>;
  rendererElement: HTMLElement | null;
  setRendererElement: (rendererElement: HTMLElement) => void;
  widgetSlotElement: HTMLDivElement | null;
  setWidgetSlotElement: (slot: HTMLDivElement) => void;
  projectKind: ProjectKind;
  projectId: ProjectId;
  projectImageTokens: DemoPlayerProject['image_tokens'];
  projectIframeScripts: DemoPlayerIframeScript[];
  playerConfig: DisplayConfigType;
  pageToFileUrl: { [key: PageId]: RendererImagePageToFileMapItem };
  imageSubstitutions: DemoPlayerImageSubstitutionsDict;
  textSubstitutions: DemoPlayerTextSubstitutionsDict;
  rendererHtmlLoader: WhitelabelLoader;
  resumeButtonConfig: {
    backgroundColor: string;
    value: string;
    color: string;
  } | null;
  replayButtonConfig: {
    backgroundColor: string;
    value: string;
    color: string;
  } | null;
  isWidgetTranslationAnimationEnabled: boolean;
  widgetAnimationDelayMs: number;
  isRendererHtmlScaleEnabled: boolean;
  isKeepScrollBetweenWidgets: boolean;
  isHtmlPreRenderingEnabled: boolean;
  isShadowDomEnabled: boolean;
  isVendorLeadFormScaleEnabled: boolean;
  shouldAddWatermark: boolean;
  watermarkLogoHref: string;
  watermarkTextHref: string;
  /** Brand (storylane) watermark in the demoplayer bottom corner */
  shouldDisplayBrandWatermark: boolean;
  /** Renderer wrapper element */
  rendererDimensions: { width: number; height: number };
  rendererWrapper: MutableRefObject<HTMLDivElement | null>;
  isReplayEnabled: boolean;
  isRecording: boolean;
  /**
   * `isResponsiveEnabled` determines if the demoplayer can adjust the rendering of elements for smaller screens.
   * It's crucial for disabling features in older demos embedded without extra space for responsive widgets.
   *
   * @see {@link https://storylane.atlassian.net/l/cp/6ufAmBF0 Docs}
   */
  isResponsiveEnabled: boolean;
  widgetAnimationDurationOutMs: number;
  widgetAnimationDurationInMs: number;
  projectPages: DemoPlayerProjectPage[];
  hostInfo: HostInfoRef | null;
  isAudioAutoplayEnabled: boolean;
  setIsAudioAutoplayEnabled: (isEnabled: boolean) => void;
  isVideoAutoplayEnabled: boolean;
  setIsVideoAutoplayEnabled: (isEnabled: boolean) => void;
  emitter: DemoPlayerEventEmitter;
}

export const DemoPlayerContext = createContext<DemoPlayerContextValue>(
  {} as unknown as DemoPlayerContextValue
);
