import { useCallback, useMemo } from 'react';
import { VendorFormWidget } from 'shared/src/features/widgets/widgets.types';
import { pxToRem } from 'shared/src/features/ui/ui.utils';
import Button from 'shared/src/uikit/Button';
import { retrieveWidthStyles } from 'shared/src/features/widgets/widgets.utils';
import WidgetBody from 'shared/src/components/widgets/WidgetBody';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import VendorLeadForm from 'shared/src/components/widgets/VendorLeadForm';

import { WidgetComponentProps } from '../Widget';
import styles from './VendorForm.module.css';
import { useWidgetPlayerState } from '../../../hooks/useWidgetPlayerState';

function VendorForm({
  widget,
  onCta,
  isMounted,
  onExit,
  onReady,
  onDismiss,
  animationDelayMs,
  durationIn,
  durationOut,
}: WidgetComponentProps<VendorFormWidget>): JSX.Element {
  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth]
  );
  const frameHeight = `${pxToRem(widget.options.root.frameHeight)}em`;
  const {
    onLeadCapture,
    projectId,
    isPreview,
    hostInfo,
    isVendorLeadFormScaleEnabled,
  } = useWidgetPlayerState();

  const handleCustomFormAction = useCallback(
    ({
      lead,
      proceed,
    }: {
      lead: {
        email?: string;
        externalId?: string;
      } & Partial<Record<string, string>>;
      proceed: boolean;
    }) => {
      onLeadCapture(lead);
      if (proceed) {
        onCta();
      }
    },
    [onLeadCapture, onCta]
  );

  const renderCustomForm = () => {
    return (
      <VendorLeadForm
        projectId={projectId}
        widgetId={widget.id}
        onSubmit={handleCustomFormAction}
        urlQuery={hostInfo?.current?.query}
        isScaleEnabled={isVendorLeadFormScaleEnabled}
      />
    );
  };

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn="top"
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetBody
        {...widthStyles}
        testId={`widget_${widget.kind}_${widget.id}`}
        height={frameHeight}
        onDismiss={onDismiss}
        widget={widget}
      >
        {isPreview ? (
          <div
            aria-label="Vendor Form Widget"
            id={`vendor_form_widget_${widget.id}`}
            className={styles.disabledForm}
          >
            <div className={styles.disabledFormOverlay}>
              <div className={styles.disabledFormOverlayDescription}>
                Lead form will appear on published demo
              </div>

              <div className={styles.disabledFormOverlayButtons}>
                <Button variant="outline" onClick={onCta}>
                  Continue
                </Button>
              </div>
            </div>

            {renderCustomForm()}
          </div>
        ) : (
          renderCustomForm()
        )}
      </WidgetBody>
    </WidgetAnimation>
  );
}

export default VendorForm;
