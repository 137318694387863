import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LeadCaptureWidget } from 'shared/src/features/widgets/widgets.types';
import WidgetBody from 'shared/src/components/widgets/WidgetBody/WidgetBody';
import Button from 'shared/src/uikit/Button';
import {
  calculateWidgetTextColor,
  retrieveWidthStyles,
} from 'shared/src/features/widgets/widgets.utils';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import WidgetText from 'shared/src/components/widgets/WidgetText';
import WidgetFooter from 'shared/src/components/widgets/WidgetFooter';

import { WidgetComponentProps } from '../Widget';
import styles from './LeadCapture.module.css';
import Input from './Input';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';
import { useWidgetPlayerState } from '../../../hooks/useWidgetPlayerState';

function LeadCapture({
  widget,
  onCta,
  isMounted,
  onExit,
  onReady,
  onPrev,
  onDismiss,
  onSecondary,
  animationDelayMs,
  durationIn,
  durationOut,
}: WidgetComponentProps<LeadCaptureWidget>): JSX.Element {
  const widgetBackgroundColor = widget.options?.root?.backgroundColor || '#fff';
  const { onLeadCapture, isPreview } = useWidgetPlayerState();

  const { register, handleSubmit, formState } = useForm<{
    email: string;
    name: string;
  }>();

  const handleFormSubmit = useCallback(
    (formFields) => {
      onLeadCapture({
        email: formFields.email,
        name: formFields.name,
      });
      onCta();
    },
    [onCta, onLeadCapture]
  );

  const onWidgetAction = isPreview ? onCta : handleSubmit(handleFormSubmit);

  const renderInternalForm = () => (
    <>
      <WidgetText
        value={widget.options.description.value}
        style={{
          color: calculateWidgetTextColor(widgetBackgroundColor, 'primary'),
        }}
      />
      <form
        aria-label="Widget lead capture"
        id={`lead_capture_widget_form_${widget.id}`}
        className={styles.form}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Input
          className={styles.input}
          label={widget.options.root.fieldEmailLabel}
          placeholder={widget.options.root.fieldEmailPlaceholder}
          type="email"
          data-testid={`lead_capture_widget_input_email`}
          error={formState.errors.email?.message}
          {...register('email', {
            required: {
              value: true,
              message: 'Email is required',
            },
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Invalid email',
            },
          })}
        />
        {widget.options.root.show_name_field && (
          <Input
            className={styles.input}
            label={widget.options.root.fieldNameLabel}
            placeholder={widget.options.root.fieldNamePlaceholder}
            error={formState.errors.name?.message}
            maxLength={100}
            data-testid={`lead_capture_widget_input_name`}
            {...register('name', {
              required: {
                value: true,
                message: 'Name is required',
              },
            })}
          />
        )}
        {/** support native "submit"  */}
        <input type="submit" hidden />{' '}
      </form>
      <WidgetText
        style={{ color: '#615e7e' }}
        value={widget.options.termsAndConditions.value}
      />
    </>
  );
  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth]
  );

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn="top"
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetBody
        id={`lead_capture_body_${widget.id}`}
        testId={`widget_${widget.kind}_${widget.id}`}
        {...widthStyles}
        onDismiss={onDismiss}
        widget={widget}
      >
        {!isPreview && renderInternalForm()}
        {isPreview && (
          <div className={styles.disabledForm}>
            <div className={styles.disabledFormOverlay}>
              <div className={styles.disabledFormOverlayDescription}>
                Lead form will appear on published demo
                <br />
                <Button
                  variant={'outline'}
                  className={styles.disabledFormOverlayButton}
                  onClick={onCta}
                >
                  Continue
                </Button>
              </div>
            </div>
            {renderInternalForm()}
          </div>
        )}
        <WidgetFooter
          totalStepsNumber={widget._totalStepsCount}
          currentStepNumber={widget._currentStepNumber}
          widget={widget}
          onSecondary={onSecondary}
          onPrev={onPrev}
          onCta={onWidgetAction}
          showPrevButton={isWidgetPreviousButtonEnabled(widget)}
          id={`lead_capture_widget_footer_${widget.id}`}
        />
      </WidgetBody>
    </WidgetAnimation>
  );
}

export default LeadCapture;
