import { useRef, useState, VFC } from 'react';
import { MediaPosition } from 'shared/src/features/widgets/widgets.constants';
import Icon from 'shared/src/uikit/Icon';
import { useHotkeys } from 'react-hotkeys-hook';
import { BaseWidget } from 'shared/src/features/widgets/widgets.types';

import styles from './VoiceOverAudio.module.css';

interface VoiceOverAudioProps {
  audioUrl?: string | null;
  alignment?: BaseWidget['media_position'];
  isAudioAutoplayEnabled: boolean;
  onAudioEnded: VoidFunction;
  onPlayPauseButtonClick: (isPlaying: boolean) => void;
  duration: number;
}

const VoiceOverAudio: VFC<VoiceOverAudioProps> = ({
  audioUrl,
  alignment,
  isAudioAutoplayEnabled,
  onAudioEnded,
  onPlayPauseButtonClick,
  duration,
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const widgetAlignment = alignment || MediaPosition.TopRight;

  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const animationFrameId = useRef<number | null>(null);

  const updateProgress = () => {
    if (audioRef.current) {
      setProgress(audioRef.current.currentTime);
      animationFrameId.current = requestAnimationFrame(updateProgress);
    }
  };

  const onClick = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        onPlayPauseButtonClick(true);
      } else {
        audioRef.current.pause();
        onPlayPauseButtonClick(false);
      }
    }
  };

  useHotkeys('Space', onClick);

  const onEnded = () => {
    setIsAudioPlaying(false);
    onAudioEnded();
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  const onTimeUpdate = () => {
    if (audioRef.current) {
      setProgress(audioRef.current.currentTime);
    }
  };

  const progressPercentage = (progress / duration) * 100;

  const onPause = () => {
    setIsAudioPlaying(false);

    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  const onPlay = () => {
    setIsAudioPlaying(true);
    animationFrameId.current = requestAnimationFrame(updateProgress);
  };

  return (
    <div
      data-alignment={widgetAlignment}
      className={styles.container}
      onClick={onClick}
    >
      <div data-alignment={widgetAlignment}>
        <div className={styles.iconWrapper}>
          <Icon
            width={50}
            height={50}
            name={isAudioPlaying ? 'sound-pause' : 'sound-play'}
            className={styles.icon}
            style={{
              background: `conic-gradient(#fff ${progressPercentage}%, transparent ${progressPercentage}% 100%)`,
            }}
          />
        </div>

        <audio
          autoPlay={isAudioAutoplayEnabled}
          className={styles.audioPlayer}
          src={audioUrl || ''}
          onPause={onPause}
          onPlay={onPlay}
          ref={audioRef}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnded}
        />
      </div>
    </div>
  );
};

export default VoiceOverAudio;
