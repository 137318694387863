import { ReactNode, useRef, useState, useEffect, CSSProperties } from 'react';
import { WidgetAlignPosition } from 'shared/src/features/widgets/widgets.constants';
import {
  HotspotWidget,
  TooltipWidget,
  WidgetPlacement,
} from 'shared/src/features/widgets/widgets.types';
import {
  getWidgetPlacementIntersection,
  isHotspot,
  isTooltip,
} from 'shared/src/features/widgets/widgets.utils';
import useAnimateScrollIntoView from 'shared/src/hooks/useAnimateScrollIntoView';
import WidgetTooltipAlignment from 'shared/src/components/widgets/WidgetTooltipAlignment';
import WidgetHotspotAlignment from 'shared/src/components/widgets/WidgetHotspotAlignment';
import observeIntersection from 'shared/src/utils/IntersectionObserver';

import styles from './TooltipPositionManagerImage.module.css';
import { useWidgetPlayerState } from '../../../hooks/useWidgetPlayerState';
import { DemoPlayerWidget } from '../../../types';

interface TooltipPositionManagerImageProps {
  widgetSlotElement: HTMLDivElement;
  widget: DemoPlayerWidget<HotspotWidget | TooltipWidget>;
  children: ReactNode;
  isDemoPlayerSmallScreen: boolean;
}

interface PositionElementStyles extends CSSProperties {
  '--translate-x': string;
  '--translate-y': string;
}

function TooltipPositionManagerImage({
  widget,
  children,
  widgetSlotElement,
  isDemoPlayerSmallScreen,
}: TooltipPositionManagerImageProps): JSX.Element {
  const { setArrowWidgetAutoPlacement } = useWidgetPlayerState();
  const alignmentRef = useRef<HTMLDivElement>(null);
  const disableAutoPlacement = isDemoPlayerSmallScreen && isTooltip(widget); // tooltip widget is completely hidden in a small screen. Nothing to position.
  const [positionElement, setPositionElement] = useState<HTMLDivElement | null>(
    null
  );
  const visitedPlacements = useRef<
    Map<
      string,
      {
        placement: WidgetPlacement;
        intersectionRatio: number;
      }
    >
  >(new Map());
  const unsubscribeIntersectionObserver = useRef<(() => void) | null>(null);
  const [isSelectingPlacement, setIsSelectingPlacement] = useState(
    disableAutoPlacement ? false : true
  ); // TODO: check this out
  const currentAlignment = useRef(widget.autoAlignment);
  if (widget.autoAlignment) {
    currentAlignment.current = widget.autoAlignment;
  }
  const currentOffset = useRef(widget.options.root.offset);
  if (widget.autoOffset) {
    currentOffset.current = widget.autoOffset;
  }

  useAnimateScrollIntoView({
    element: positionElement,
    delay: 500,
  });

  useEffect(() => {
    if (disableAutoPlacement) {
      return;
    }

    /**
     * If widget is clipped, then try to find better placement
     * @see {@link https://storylane.atlassian.net/l/cp/K15hdmdn Widget Auto Placement docs}
     */
    if (alignmentRef.current) {
      try {
        unsubscribeIntersectionObserver.current = observeIntersection({
          element: alignmentRef.current,
          triggerOnce: false,
          root: widgetSlotElement,
          threshold: [
            0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.2,
            0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95,
            0.96, 0.97, 0.98, 0.99, 1,
          ],
          cb: (data) => {
            const {
              intersectionRatio, // Returns the ratio of the intersectionRect to the boundingClientRect.
            } = data[0];

            // widget is fully visible with an initial alignment and offset
            if (intersectionRatio === 1) {
              setIsSelectingPlacement(false);
              return;
            }

            const currentPlacement = {
              alignment: currentAlignment.current,
              offset: currentOffset.current,
            };

            const {
              done,
              placement,
              visitedPlacements: nextVisitedPlacements,
            } = getWidgetPlacementIntersection({
              intersectionEntry: data[0],
              currentPlacement,
              visitedPlacements: visitedPlacements.current,
              widgetBackdropRect: widget.anchorBackdropRect,
            });

            visitedPlacements.current = nextVisitedPlacements;
            setArrowWidgetAutoPlacement((prev) => ({
              ...prev,
              [widget.id]: placement,
            }));
            // searching for a placement is done
            if (done) {
              setIsSelectingPlacement(false);
              unsubscribeIntersectionObserver.current?.(); // unsubscribe IntersectionObserver
            }
          },
        });
        return () => {
          unsubscribeIntersectionObserver.current?.();
        };
      } catch (error) {
        console.warn(error);
      }
    }
  }, [
    widget.options.root.alignment,
    widget.options.root.offset,
    widget.anchorBackdropRect,
    setArrowWidgetAutoPlacement,
    widgetSlotElement,
    widget.id,
    disableAutoPlacement,
  ]);

  return (
    <div
      className={styles.root}
      style={
        {
          '--translate-x': `${currentOffset.current.x}%`,
          '--translate-y': `${currentOffset.current.y}%`,
        } as PositionElementStyles
      }
    >
      <div ref={setPositionElement} className={styles.rootImageTooltipContent}>
        {isTooltip(widget) && (
          <WidgetTooltipAlignment
            ref={alignmentRef}
            alignment={currentAlignment.current as WidgetAlignPosition}
            isHidden={isSelectingPlacement}
            isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
          >
            {children}
          </WidgetTooltipAlignment>
        )}
        {isHotspot(widget) && (
          <WidgetHotspotAlignment
            ref={alignmentRef}
            alignment={currentAlignment.current as WidgetAlignPosition}
            isHidden={isSelectingPlacement}
            onlyBeacon={widget.hotspotOnlyBeacon || isDemoPlayerSmallScreen}
          >
            {children}
          </WidgetHotspotAlignment>
        )}
      </div>
    </div>
  );
}

export default TooltipPositionManagerImage;
