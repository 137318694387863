import { RefObject, VFC } from 'react';
import Icon from 'shared/src/uikit/Icon';

import styles from './WidgetBottomTrayNavigation.module.css';

const WidgetBottomTrayNavigation: VFC<{
  showPrev: boolean;
  showCta: boolean;
  onCta: () => void;
  onPrev: () => void;
  totalStepsCount: number;
  currentStepNumber: number;
  ctaRef: RefObject<HTMLButtonElement>;
  prevRef: RefObject<HTMLButtonElement>;
  hideShadow: boolean;
}> = (props) => {
  const {
    showPrev,
    totalStepsCount,
    currentStepNumber,
    onPrev,
    onCta,
    showCta,
    ctaRef,
    prevRef,
    hideShadow,
  } = props;
  return (
    <div className={styles.navigation} data-hide-shadow={hideShadow}>
      {showPrev && (
        <button ref={prevRef} className={styles.prevBtn} onClick={onPrev}>
          <Icon name="widget-prev-step" width="16" height="12" />
        </button>
      )}

      <div className={styles.step}>
        <span className={styles.currentStep}>{currentStepNumber}</span>/
        {totalStepsCount}
      </div>

      <div className={styles.cta}>
        {showCta && (
          <button ref={ctaRef} className={styles.ctaBtn} onClick={onCta}>
            <Icon name="widget-next-step" width="16" height="12" />
          </button>
        )}
      </div>
    </div>
  );
};

export default WidgetBottomTrayNavigation;
