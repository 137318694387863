import { useEffect, useCallback, VFC, useContext } from 'react';
import { ProjectKind } from 'shared/src/features/project/project.constants';
import { WidgetId } from 'shared/src/features/widgets/widgets.types';
import ErrorBoundary from 'shared/src/uikit/ErrorBoundary';

import DemoPlayerFallback from '../DemoPlayerFallback';
import DemoPlayerHtml from '../DemoPlayerHtml';
import DemoPlayerImage from '../DemoPlayerImage';
import { usePageRenderStatus } from './DemoPlayer.hooks';
import { usePlayerState } from '../../hooks/usePlayerState';
import {
  DemoPlayerConfig,
  DemoPlayerFlowlist,
  DemoPlayerFlowsDict,
  DemoPlayerImageSubstitutionsDict,
  DemoPlayerProject,
  DemoPlayerProjectPage,
  DemoPlayerTextSubstitutionsDict,
  DemoPlayerWidgetsDict,
  HostInfoRef,
} from '../../types';
import { useInteractivePlayerState } from '../../hooks/useInteractivePlayerState';
import DemoPlayerEventEmitter from '../../events/emitter';
import { demoplayerEventCreators } from '../../events/eventCreators';
import DemoPlayerStateProvider from '../DemoPlayerStateProvider';
import DemoPlayerWidgetStateProvider from '../DemoPlayerWidgetStateProvider';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';

type _DemoPlayerProps = Pick<DemoPlayerProps, 'onOpenExtUrl' | 'startPlayback'>;

const _DemoPlayer: VFC<_DemoPlayerProps> = (props) => {
  const { startPlayback = true, onOpenExtUrl } = props;
  const {
    projectKind,
    currentPageId,
    currentFlowId,
    currentWidgetId,
    startPlayer,
    isPlayerReadyState,
    isInteractiveState,
    isFlowPlaybackState,
    isLastWidgetInFlow,
    playerConfig,
    startPageId,
  } = usePlayerState();
  const { emitter } = useContext(DemoPlayerContext);

  const { goToPage } = useInteractivePlayerState();

  // Handle external page change request
  useEffect(() => {
    if (emitter) {
      const pageChangeHandler = (payload: { pageId: string }) => {
        goToPage(payload.pageId);
      };

      return emitter.on(
        demoplayerEventCreators.requestPageChangeEvent,
        pageChangeHandler
      );
    }
  }, [emitter, goToPage, startPageId]);

  const onFirstRender = useCallback(() => {
    emitter.emit(demoplayerEventCreators.recordingStartReadyEvent, {
      pageId: currentPageId,
    });
  }, [currentPageId, emitter]);

  const [isPageRendered, setIsPageRendered] = usePageRenderStatus({
    onFirstRender,
  });

  useEffect(() => {
    /**
     * Start playback when "startPlayback" flag is coming
     */
    if (startPlayback && isPlayerReadyState) {
      startPlayer();
    }
  }, [startPlayer, startPlayback, isPlayerReadyState]);

  useEffect(() => {
    /**
     * invoke page change callback (only when in flow-playback\interactive state)
     */
    if (isFlowPlaybackState || isInteractiveState) {
      emitter.emit(demoplayerEventCreators.pageChangeEvent, {
        pageId: currentPageId,
      });
    }
  }, [currentPageId, emitter, isFlowPlaybackState, isInteractiveState]);

  useEffect(() => {
    /**
     * invoke flow change callback (only when in playback state)
     */
    if (currentFlowId && isFlowPlaybackState) {
      emitter.emit(demoplayerEventCreators.flowChangeEvent, {
        flowId: currentFlowId,
      });
    }
  }, [emitter, currentFlowId, isFlowPlaybackState, isInteractiveState]);

  useEffect(() => {
    /**
     * invoke widget change callback (only when in playback state)
     */
    if (currentWidgetId && isFlowPlaybackState) {
      emitter.emit(demoplayerEventCreators.widgetChangeEvent, {
        widgetId: currentWidgetId,
      });
      if (isLastWidgetInFlow && currentFlowId) {
        emitter.emit(demoplayerEventCreators.lastWidgetEnterEvent, {
          flowId: currentFlowId,
          widgetId: currentWidgetId,
        });
      }
    }
  }, [
    currentFlowId,
    currentWidgetId,
    isFlowPlaybackState,
    isLastWidgetInFlow,
    emitter,
  ]);

  const handleOpenExtUrl = useCallback<
    Exclude<DemoPlayerProps['onOpenExtUrl'], undefined>
  >(
    (data, widgetId) => {
      if (onOpenExtUrl) {
        const parsedData: { url: string; target: '_blank' | '_self' } = {
          ...data,
        };

        /**
         * In Editor we do not validate URL string provided by the user.
         * Original thought: It's up to the user to provide a valid URL - no time for adding validation and input-mask.
         *
         * Process url to make a well-formatted absolute URL.
         * TODO: remove this when all URLs in database are fixed and URL is getting validated in Editor.
         */
        const protocolRegexp = /^https?:\/\//i;
        const mailtoRegexp = /^mailto:/i;
        if (
          parsedData.url &&
          !mailtoRegexp.test(parsedData.url) &&
          !protocolRegexp.test(parsedData.url)
        ) {
          parsedData.url = `https://${data.url}`;
        }
        try {
          if (parsedData?.url) {
            new URL(parsedData.url);
          }
        } catch (error) {
          console.error(`Invalid absolute url: ${parsedData.url}`);
        }
        return onOpenExtUrl(parsedData, widgetId);
      }
    },
    [onOpenExtUrl]
  );

  const isCustomCtaEnabled = playerConfig.cta_enabled;

  if (projectKind === ProjectKind.Html) {
    return (
      <DemoPlayerHtml
        isRendererReady={isPageRendered}
        onRendererReady={setIsPageRendered}
        onOpenExtUrl={handleOpenExtUrl}
        isCustomCtaEnabled={isCustomCtaEnabled}
      />
    );
  } else if (projectKind === ProjectKind.Image) {
    return (
      <DemoPlayerImage
        isRendererReady={isPageRendered}
        onRendererReady={setIsPageRendered}
        onOpenExtUrl={handleOpenExtUrl}
        isCustomCtaEnabled={isCustomCtaEnabled}
      />
    );
  } else {
    throw new Error('Unknown project kind');
  }
};

export type ExtUrlTarget = '_blank' | '_self';

export interface DemoPlayerProps {
  project: DemoPlayerProject;
  projectWidgets: DemoPlayerWidgetsDict;
  projectFlows: DemoPlayerFlowsDict;
  projectFlowlist: DemoPlayerFlowlist | null;
  pages: DemoPlayerProjectPage[];
  startPlayback: boolean;
  config: DemoPlayerConfig;
  emitter: DemoPlayerEventEmitter;
  hostInfo?: HostInfoRef;
  textSubstitutions?: DemoPlayerTextSubstitutionsDict;
  imageSubstitutions?: DemoPlayerImageSubstitutionsDict;
  onOpenExtUrl?: (
    data: { url: string; target: ExtUrlTarget },
    widgetId?: WidgetId
  ) => void;
}

function DemoPlayer({
  project,
  projectFlows,
  textSubstitutions,
  imageSubstitutions,
  projectWidgets,
  projectFlowlist,
  pages,
  config,
  hostInfo,
  emitter,
  ...props
}: DemoPlayerProps): JSX.Element {
  return (
    // FIXME this will be fixed after @types/react update to 18 version
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ErrorBoundary fallback={<DemoPlayerFallback />}>
      <DemoPlayerStateProvider
        project={project}
        textSubstitutions={textSubstitutions}
        imageSubstitutions={imageSubstitutions}
        projectFlows={projectFlows}
        projectWidgets={projectWidgets}
        projectFlowlist={projectFlowlist}
        projectPages={pages}
        config={config}
        hostInfo={hostInfo}
        emitter={emitter}
      >
        <DemoPlayerWidgetStateProvider config={config}>
          <_DemoPlayer {...props} />
        </DemoPlayerWidgetStateProvider>
      </DemoPlayerStateProvider>
    </ErrorBoundary>
  );
}

export default DemoPlayer;
