import { useContext, useRef, useState, VFC } from 'react';
import cx from 'classnames';
import { Video, VideoContext } from 'shared/src/components/Video';
import VideoPlayerProvider from 'shared/src/components/Video/VideoContextProvider';
import { useHotkeys } from 'react-hotkeys-hook';

import styles from './VideoOver.module.css';
import { MediaPosition } from '../../features/widgets/widgets.constants';
import { BaseWidget, StreamStatus } from '../../features/widgets/widgets.types';
import Icon from '../../uikit/Icon';
import { getVideoThumbnail } from './VideoOver.utils';
import { calculateTime } from '../widgets/AudioPlayer/AudioPlayer.utils';

interface VideoOverProps {
  position?: BaseWidget['media_position'];
  mediaDuration?: number | null;
  isAutoPlay?: boolean;
  onEnded?: VoidFunction;
  onPlayPauseButtonClick?: (isPlaying: boolean) => void;
  streamStatus?: StreamStatus;
  streamUrl?: string | null;
  thumbnailUrl?: string | null;
}

const _VideoOver: VFC<VideoOverProps> = ({
  position = MediaPosition.TopRight,
  mediaDuration = 0,
  onEnded,
  onPlayPauseButtonClick,
  streamStatus,
  streamUrl,
  thumbnailUrl,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const animationFrameId = useRef<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const { videoRef } = useContext(VideoContext);

  const handlePlayPause = () => {
    if (isPlaying) {
      onPlayPauseButtonClick?.(false);
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
      onPlayPauseButtonClick?.(true);
    }
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const current = videoRef.current.currentTime;
      if (current) {
        setCurrentTime(current);
      }

      animationFrameId.current = requestAnimationFrame(updateProgress);
    }
  };

  const onPlay = () => {
    setIsPlaying(true);
    animationFrameId.current = requestAnimationFrame(updateProgress);
  };

  const onVideoEnded = () => {
    setIsPlaying(false);
    if (onEnded) onEnded();
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  const onPause = () => {
    setIsPlaying(false);
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  useHotkeys('Space', handlePlayPause);

  const progress = currentTime / (mediaDuration || 1);

  const onTimeUpdate: React.ReactEventHandler<HTMLVideoElement> = (event) => {
    if (event.currentTarget.currentTime) {
      setCurrentTime(event.currentTarget.currentTime);
    }
  };

  if (streamStatus === 'preparing') {
    return (
      <div
        data-position={position}
        className={cx(styles.videoPlayer, styles.loadPlayerWrapper)}
      >
        <div
          className={cx(styles.recordedVideo, styles.loadingRecorder)}
          style={
            {
              '--progress': progress,
              '--progress-color': '#1A1348', // Progress color
              '--border-color': '#eee', // Base border color
            } as React.CSSProperties
          }
        >
          <div className={styles.progressLabel}>
            The video is in progress. it might take a few minutes.
          </div>
        </div>
      </div>
    );
  }

  const renderedTimer = (mediaDuration || 0) - currentTime;

  return (
    <div data-position={position} className={styles.videoPlayer}>
      <div
        className={cx(styles.recordedVideo)}
        onClick={handlePlayPause}
        style={
          {
            '--progress': progress,
            '--progress-color': '#1A1348', // Progress color
            '--border-color': '#eee', // Base border color
          } as React.CSSProperties
        }
      >
        {streamUrl && (
          <Video
            onTimeUpdate={onTimeUpdate}
            onEnded={onVideoEnded}
            onPlay={onPlay}
            onPause={onPause}
            className={styles.videoScreen}
            src={streamUrl}
            thumbnailUrl={getVideoThumbnail(thumbnailUrl || '')}
          />
        )}

        {!isPlaying && (
          <div className={`${styles.iconWrapper} ${styles.show}`}>
            <Icon width={45} height={45} name="play-video" />
          </div>
        )}

        {isPlaying && (
          <div className={styles.iconWrapper}>
            <Icon width={45} height={45} name="pause-video" />
          </div>
        )}

        <div className={styles.recordingTimer}>
          {calculateTime(renderedTimer <= 0 ? 0 : renderedTimer)}
        </div>
      </div>
    </div>
  );
};

const VideoOver = (props: VideoOverProps) => {
  return (
    <VideoPlayerProvider
      defaultValues={{
        isPlaying: props.isAutoPlay || false,
        isHlsVideo: true,
        isMuted: false,
      }}
    >
      <_VideoOver {...props} />
    </VideoPlayerProvider>
  );
};

export default VideoOver;
