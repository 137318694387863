import cx from 'classnames';
import { VFC } from 'react';
import Icon from 'shared/src/uikit/Icon';

import styles from './CustomizableButton.module.css';

type CustomizableButtonProps = {
  text: string;
  color: string;
  className?: string;
  onClick: () => void;
  bgColor: string;
  isResponsive: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const CustomizableButton: VFC<CustomizableButtonProps> = ({
  text,
  color,
  className,
  onClick,
  bgColor,
  isResponsive,
  ...rest
}) => {
  return (
    <button
      className={cx(styles.root, className, {
        [styles.rootResponsive]: isResponsive,
      })}
      style={{ backgroundColor: bgColor, color }}
      onClick={onClick}
      {...rest}
    >
      <Icon name="replay" className={cx(styles.icon)} />
      {text}
    </button>
  );
};

export default CustomizableButton;
