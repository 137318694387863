import { useCallback, useContext, useState, VFC } from 'react';

import Video, { VideoProps } from '../Video';
import styles from './VideoWithControls.module.css';
import Button from '../../../uikit/Button';
import VideoContext from '../video.context';

interface VideoWithControlsProps extends VideoProps {
  showMute?: boolean;
  showPlay?: boolean;
}

const VideoWithControls: VFC<VideoWithControlsProps> = ({
  showMute = false,
  showPlay = false,
  ...restProps
}) => {
  const { isPlaying, isMuted, setIsMuted, setIsPlaying, hasAudio } =
    useContext(VideoContext);

  // Defines if play button should be shown initially, and this should be set to false after any interaction
  const [showPlayInitially, setShowPlayInitially] = useState(showPlay);

  const handleMuteClick = useCallback(() => {
    setIsMuted((prevState) => !prevState);
  }, [setIsMuted]);

  const handlePlayPauseClick = useCallback(
    (_isVideoPlaying: boolean) => {
      setIsPlaying(!_isVideoPlaying);
    },
    [setIsPlaying]
  );

  return (
    <div className={styles.root}>
      {showMute && hasAudio && (
        <Button
          onClick={handleMuteClick}
          className={styles.muteButton}
          variant="inline"
          icon={isMuted ? 'filled-no-audio' : 'filled-audio'}
          size="xl"
        />
      )}
      {(showPlay || showPlayInitially) && (
        <Button
          onClick={() => {
            setShowPlayInitially(false);
            handlePlayPauseClick(isPlaying);
          }}
          className={styles.playButton}
          variant="purple"
          icon={isPlaying ? 'pause' : 'play'}
          size="lg"
        />
      )}
      <Video
        onVideoAutoplayFailed={() => setShowPlayInitially(true)}
        {...restProps}
      />
    </div>
  );
};
VideoWithControls.displayName = 'VideoWithControls';

export default VideoWithControls;
