import { useCallback, useRef } from 'react';
import { useEffectOnce } from 'shared/src/hooks/react-use/useEffectOnce';
import { ProjectImageToken } from 'shared/src/features/projectImageToken/projectImageToken.types';
import {
  getElementByUniqueSelector,
  getFinalFrameSelectorFromUniqueSelector,
} from 'shared/src/utils/unique-selector';
import {
  getSVGSubstitutionDomId,
  injectSVGSubstitutionsContainer,
} from 'shared/src/features/renderer/renderer.utils';
import {
  hasBackgroundImage,
  isImageElement,
  isSVGElement,
} from 'shared/src/utils/dom';
import { rendererSVGSubstitutionsContainerId } from 'shared/src/features/renderer/renderer.constants';
import {
  getPrioritizedImageToken,
  isImageTokenAvailableInPage,
} from 'shared/src/features/projectImageToken/projectImageToken.utils';

import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';

/**
 * `useDemoPlayerImageTokens` hook returns a callback which replaces all "image token" in a provided `Document`.
 *
 * @returns
 *
 * @see {@link https://storylane.atlassian.net/l/cp/0BsAzqi8 Docs }
 */
function useDemoPlayerImageTokens(): {
  /**
   * Callback to replace "image tokens" in a loaded document
   */
  replaceImageTokens: (loadedDocument: Document) => Document;
} {
  const { currentPageId, projectImageTokens, imageSubstitutions } =
    useRendererPlayerState();

  const imageTokens = useRef<ProjectImageToken[]>();
  if (!imageTokens.current) {
    imageTokens.current = Object.values(projectImageTokens).filter(
      (imageToken) => imageSubstitutions[imageToken.substitution_id] // fallback: exclude "image tokens" which substitutions are not presented(perfectly: BE shouldn't provide such tokens)
    );
  }
  const currentPageIdRef = useRef(currentPageId);
  currentPageIdRef.current = currentPageId;

  const svgSpriteText = useRef<Promise<string[]> | string | null>(null);
  useEffectOnce(() => {
    const svgRequests: Promise<string>[] = [];
    imageTokens.current?.forEach((imageToken) => {
      const correspondingSubstitution =
        imageSubstitutions[imageToken.substitution_id];
      if (correspondingSubstitution.options.fileType === 'image/svg+xml') {
        svgRequests.push(
          fetch(correspondingSubstitution.value as string)
            .then((res) => res.text())
            .then((svgText) => {
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = svgText;
              const svg = tempDiv.firstChild as SVGElement;
              svg.id = getSVGSubstitutionDomId(imageToken.substitution_id);
              const result = svg.outerHTML;
              return result;
            })
        );
      }
    });

    const promiseAll = Promise.all(svgRequests);
    svgSpriteText.current = promiseAll;
    promiseAll.then((res) => {
      svgSpriteText.current = res.join();
    });
  });

  const injectSVGSpriteIntoDocument = useCallback((doc: Document | null) => {
    const injectStartPageId = currentPageIdRef.current;
    if (typeof svgSpriteText.current === 'string') {
      const spriteBox = doc?.getElementById(
        rendererSVGSubstitutionsContainerId
      );
      if (spriteBox) {
        spriteBox.innerHTML = svgSpriteText.current;
      }
    } else if (svgSpriteText.current) {
      svgSpriteText.current.then(() => {
        if (injectStartPageId === currentPageIdRef.current) {
          if (doc) injectSVGSpriteIntoDocument(doc);
        }
      });
    }
  }, []);

  /**
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/13172737/Image+tokens#How-to-replace-image-tokens-in-html Docs}
   */
  const replaceImageTokens = useCallback(
    // TODO: provided depth of `loadedDocument` ??
    (loadedDocument: Document) => {
      if (
        loadedDocument &&
        loadedDocument.body // according to TS - `body` is always presented, but that's not true.
      ) {
        injectSVGSubstitutionsContainer(loadedDocument);
        // collect all elements which are connected to "image tokens".
        const elementTokenMap = new Map<HTMLElement, ProjectImageToken>();
        imageTokens.current?.forEach((imageToken) => {
          if (
            isImageTokenAvailableInPage(imageToken, currentPageIdRef.current)
          ) {
            const loadedDocumentLevelSelector =
              getFinalFrameSelectorFromUniqueSelector(imageToken.selector);
            const el = getElementByUniqueSelector(
              loadedDocumentLevelSelector,
              loadedDocument
            ) as HTMLElement | null;
            if (el) {
              const anotherImageToken = elementTokenMap.get(el); // get another "image token" which is connected to the same element. @see ... TODO: add documentation about this.

              // add element to collection if there is no other "image token" OR current "image token" is more specific
              if (
                !anotherImageToken ||
                getPrioritizedImageToken(anotherImageToken, imageToken) ===
                  imageToken
              ) {
                elementTokenMap.set(el, imageToken);
              }
            }
          }
        });

        // apply substitution image to an element
        for (const [element, imageToken] of elementTokenMap) {
          const {
            value: substitutionImageUrl,
            options: { fileType },
          } = imageSubstitutions[imageToken.substitution_id];
          if (isImageElement(element)) {
            element.src = substitutionImageUrl as string;
          } else if (isSVGElement(element)) {
            if (fileType === 'image/svg+xml') {
              const svgImageDomId = getSVGSubstitutionDomId(
                imageToken.substitution_id
              );
              element.innerHTML = `<use href="#${svgImageDomId}" />`;
              injectSVGSpriteIntoDocument(element.ownerDocument);
            } else {
              element.innerHTML = `<image width="100%" height="100%" href="${substitutionImageUrl}" />`;
            }
          } else if (hasBackgroundImage(element)) {
            element.style.backgroundImage = `url('${substitutionImageUrl}')`;
          }
        }
      }
      return loadedDocument;
    },
    [imageSubstitutions, injectSVGSpriteIntoDocument]
  );

  return {
    replaceImageTokens,
  };
}

export default useDemoPlayerImageTokens;
