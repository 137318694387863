import { CSSProperties } from 'react';
import { compare } from 'stacking-order';
import memoize from 'lodash-es/memoize';
import { captureException } from '@sentry/react';
import { getNestedBoundingClientRect } from 'shared/src/utils/dom';

import { THighlight } from './HintsLayer.types';

const memoStackingOrderCompare = memoize(compare, (a, b) => {
  function keyElement(el: Element) {
    return (
      el.nodeName +
      (el.id ? `#${el.id}` : '') +
      (el.className ? `.${el.className}` : '')
    );
  }

  return `${keyElement(a)}|${keyElement(b)}`;
});

/**
 * temp disabled in https://storylane.atlassian.net/browse/STORY-1505
 */
export function isElementAbove(el: Element, doc = document) {
  try {
    const bounds = el.getBoundingClientRect();

    const elements = new Set<Element>();

    // iterate diagonally and find all elements at the coordinates
    // * `1px` inset is added to the min/max to avoid overlapping with adjacent elements
    const minX = Math.min(bounds.left, bounds.right) + 1;
    const maxX = Math.max(bounds.left, bounds.right) - 1;
    const minY = Math.min(bounds.top, bounds.bottom) + 1;
    const maxY = Math.max(bounds.top, bounds.bottom) - 1;

    // diagonally
    for (let y = minY; y <= maxY; y++) {
      for (let x = minX; x <= maxX; x++) {
        if (x - minX === y - minY || x + y === maxX + minY) {
          const els = doc.elementsFromPoint(x, y);
          for (const el of els) {
            elements.add(el);
          }
        }
      }
    }

    // anti-diagonally
    for (let y = minY; y <= maxY; y++) {
      for (let x = maxX; x >= minX; x--) {
        if (x - maxX === y - minY || x + y === minX + maxY) {
          const els = doc.elementsFromPoint(x, y);
          for (const el of els) {
            elements.add(el);
          }
        }
      }
    }

    // pick highest element from elements above
    const highestElement = Array.from(elements.values()).reduce<Element | null>(
      (highest, element) => {
        if (!highest) {
          return element;
        }

        if (memoStackingOrderCompare(element, highest) === 1) {
          return element;
        }

        return highest;
      },
      null
    );

    // compare target and highest elements
    const isElAbove =
      highestElement !== null &&
      !el.contains(highestElement) &&
      el !== highestElement &&
      memoStackingOrderCompare(highestElement, el) === 1;

    return isElAbove;
  } catch (e) {
    captureException(e);

    return false;
  }
}

export type HighlightDimensionsStyles = Partial<{
  '--top': string;
  '--left': string;
  '--width': string;
  '--height': string;
}> &
  CSSProperties;

export function getDimsStyle(
  ref: Element,
  renderer: HTMLIFrameElement
): HighlightDimensionsStyles {
  if (!ref || !renderer.contentWindow) return {};
  const dims = getNestedBoundingClientRect(ref, renderer.contentWindow);

  return {
    '--top': `${dims.top}px`,
    '--left': `${dims.left}px`,
    '--width': `${dims.width}px`,
    '--height': `${dims.height}px`,
  };
}

export function isHighlightChanged(a: THighlight, b: THighlight) {
  return (
    a.id !== b.id ||
    a.isVisible !== b.isVisible ||
    a.hasDims !== b.hasDims ||
    a.dimsStyle['--top'] !== b.dimsStyle['--top'] ||
    a.dimsStyle['--left'] !== b.dimsStyle['--left'] ||
    a.dimsStyle['--width'] !== b.dimsStyle['--width'] ||
    a.dimsStyle['--height'] !== b.dimsStyle['--height']
  );
}
