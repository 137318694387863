import { useState, useMemo, CSSProperties, useEffect, useRef } from 'react';
import throttle from 'lodash-es/throttle';
import { hasDims, isElementVisible } from 'shared/src/utils/dom';
import { useRendererHtmlListener } from 'shared/src/features/renderer/renderer.hooks';
import { IframeEventsManagerListeners } from 'shared/src/utils/IframeEventsManager';
import { useEffectOnce } from 'shared/src/hooks/react-use/useEffectOnce';
import { CSSTransition } from 'react-transition-group';
import { addRAF, removeRAF } from 'shared/src/utils/raf';
import { PageLinkId } from 'shared/src/features/pageLink/pageLink.types';

import styles from './HintsLayer.module.css';
import { getDimsStyle, isHighlightChanged } from './HintsLayer.utils';
import { InteractiveHintsLayerProps, THighlight } from './HintsLayer.types';
import { CheckHighlightsTime, FadeInOutTime } from './HintsLayer.constants';

function InteractiveHintsLayer({
  linkedElements,
  renderer,
  isMissClick,
  isHover,
  hoveredLink,
}: InteractiveHintsLayerProps): JSX.Element | null {
  const highlightsRef = useRef<Map<Element, THighlight>>(new Map());
  const [isAllLinksVisible, setIsAllLinksVisible] = useState<boolean>(false);
  const [visibleLinkId, setVisibleLinkId] = useState<PageLinkId>('');

  const clickListener = useMemo(() => {
    return throttle(
      ({ target }: MouseEvent) => {
        for (const linkedElement of linkedElements.keys()) {
          if (
            linkedElement === target ||
            linkedElement.contains(target as Node | null)
          ) {
            return; // is a link
          }
        }
        setIsAllLinksVisible(true);
      },
      FadeInOutTime,
      { trailing: false }
    );
  }, [linkedElements, setIsAllLinksVisible]);

  const keydownListener = useMemo(() => {
    return throttle(
      ({ ctrlKey }: KeyboardEvent) => {
        if (ctrlKey) {
          setIsAllLinksVisible(true);
        }
      },
      FadeInOutTime,
      { trailing: false }
    );
  }, [setIsAllLinksVisible]);

  const syncHighlights = () => {
    let isChanged = false;
    for (const [el, highlight] of highlightsRef.current.entries()) {
      const newHighlight: THighlight = {
        id: highlight.id,
        hasDims: hasDims(el),
        isVisible: isElementVisible(el),
        dimsStyle: getDimsStyle(el, renderer),
      };
      const curHighlight = highlightsRef.current.get(el);
      if (curHighlight) {
        if (isHighlightChanged(curHighlight, newHighlight)) {
          highlightsRef.current.set(el, newHighlight);
          isChanged = true;
        }
      } else {
        highlightsRef.current.set(el, newHighlight);
        isChanged = true;
      }
    }

    if (isChanged) {
      highlightsRef.current = new Map(highlightsRef.current);
    }
    return false;
  };

  useEffectOnce(() => {
    document.addEventListener('keydown', keydownListener);
    addRAF({
      cb: syncHighlights,
      interval: CheckHighlightsTime,
    });
    return () => {
      document.removeEventListener('keydown', keydownListener);
      removeRAF(syncHighlights);
    };
  });

  const listeners = useMemo(() => {
    const result: IframeEventsManagerListeners = [];
    if (isMissClick) {
      result.push(['click', clickListener, { attachToShadowDom: false }]);
      result.push(['keydown', keydownListener, { attachToShadowDom: false }]);
    }
    return result;
  }, [isMissClick, clickListener, keydownListener]);

  useRendererHtmlListener({
    rendererElement: renderer,
    listeners: listeners,
  });

  useEffect(() => {
    if (hoveredLink) {
      setVisibleLinkId(hoveredLink.id);
    } else {
      setVisibleLinkId('');
    }
  }, [hoveredLink]);

  const [, updateState] = useState<{ now: number }>();
  useEffect(() => {
    const newMap = new Map<Element, THighlight>();
    for (const [el, { id }] of linkedElements.entries()) {
      const newHighlight: THighlight = {
        id,
        hasDims: hasDims(el),
        isVisible: isElementVisible(el),
        dimsStyle: getDimsStyle(el, renderer),
      };
      const highlight = newMap.get(el);
      if (highlight) {
        if (isHighlightChanged(highlight, newHighlight)) {
          newMap.set(el, newHighlight);
        }
      } else {
        newMap.set(el, newHighlight);
      }
    }

    highlightsRef.current = newMap;
    updateState({ now: Date.now() }); // force re-render to mount CSSTransition elements
  }, [linkedElements, renderer]);

  return (
    <div className={styles.root}>
      {[...highlightsRef.current.values()].map(({ id, dimsStyle }) => {
        return (
          <CSSTransition
            key={id}
            in={
              isAllLinksVisible ||
              (Boolean(visibleLinkId) && visibleLinkId === id)
            }
            timeout={FadeInOutTime / 2}
            onEntered={() => {
              setIsAllLinksVisible(false);
              if (isHover) {
                if (!hoveredLink) {
                  setVisibleLinkId('');
                }
              }
            }}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
            style={
              {
                ...dimsStyle,
                '--animation-time': `${FadeInOutTime}ms`,
              } as CSSProperties
            }
          >
            <div className={styles.highlight} />
          </CSSTransition>
        );
      })}
    </div>
  );
}

export default InteractiveHintsLayer;
