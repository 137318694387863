import { CSSProperties, FC } from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import styles from './WatermarkInline.module.css';

export interface WatermarkInlineProps {
  href?: string;
  className?: string;
  style?: CSSProperties;
}

const WatermarkInline: FC<WatermarkInlineProps> = (props) => {
  const { className, style } = props;

  return (
    <div className={cx(styles.root, className)} style={style}>
      Made with&nbsp;
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <Icon name="logo-no-text" className={styles.logo} />
        &nbsp;
        <span className={styles.name}>Storylane</span>
      </a>
    </div>
  );
};

export default WatermarkInline;
