import { useMemo, lazy, Suspense, FC } from 'react';
import { MediaWidget } from 'shared/src/features/widgets/widgets.types';
import WidgetBody from 'shared/src/components/widgets/WidgetBody/WidgetBody';
import {
  calculateWidgetTextColor,
  retrieveWidthStyles,
} from 'shared/src/features/widgets/widgets.utils';
import Skeleton from 'shared/src/uikit/Skeleton';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import WidgetScrollableContainer from 'shared/src/components/widgets/WidgetScrollableContainer';
import MediaWidgetImage from 'shared/src/components/widgets/MediaWidgetImage';
import WidgetFooter from 'shared/src/components/widgets/WidgetFooter';
import WidgetText from 'shared/src/components/widgets/WidgetText';

import { WidgetComponentProps } from '../Widget';
import styles from './Media.module.css';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';

const ReactPlayer = lazy(
  /* webpackChunkName: "ReactPlayer" */ () => import('react-player')
);

const Media: FC<WidgetComponentProps<MediaWidget>> = ({
  widget,
  onCta,
  isMounted,
  isDemoPlayerSmallScreen,
  onExit,
  onReady,
  onPrev,
  onDismiss,
  onSecondary,
  animationDelayMs,
  durationIn,
  durationOut,
}) => {
  const { options, watermarkEnabled, watermarkTextHref } = widget;
  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';
  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth]
  );

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn="top"
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetBody
        {...widthStyles}
        className={styles.media}
        widget={widget}
        onDismiss={onDismiss}
        shouldAddWatermark={watermarkEnabled}
        watermarkHref={watermarkTextHref}
        testId={`widget_${widget.kind}_${widget.id}`}
        id={`media_widget_${widget.id}`}
      >
        <WidgetScrollableContainer>
          {widget.image_url && !options?.image?.hidden && (
            <MediaWidgetImage widget={widget} />
          )}
          {widget.video_url && (
            <div className={styles.videoWrap}>
              {/* svg creates container with aspect ratio 16/9 with auto scale*/}
              <svg viewBox="0 0 16 9" className={styles.videoPlaceholder} />
              <div className={styles.player}>
                <Suspense fallback={<Skeleton fill />}>
                  <ReactPlayer
                    url={widget.video_url}
                    width={'100%'}
                    height={'100%'}
                    playing
                    playsinline
                    controls
                  />
                </Suspense>
              </div>
            </div>
          )}
          <WidgetText
            value={options.description.value}
            style={{
              color: calculateWidgetTextColor(widgetBackgroundColor, 'primary'),
            }}
          />
        </WidgetScrollableContainer>
        {!isDemoPlayerSmallScreen && (
          <WidgetFooter
            widget={widget}
            currentStepNumber={widget._currentStepNumber}
            totalStepsNumber={widget._totalStepsCount}
            onSecondary={onSecondary}
            onCta={onCta}
            onPrev={onPrev}
            showPrevButton={isWidgetPreviousButtonEnabled(widget)}
            id={`widget_footer_${widget.id}`}
          />
        )}
      </WidgetBody>
    </WidgetAnimation>
  );
};

export default Media;
