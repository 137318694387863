import { useEffect, useCallback, useRef, useState, useMemo, VFC } from 'react';
import cx from 'classnames';
import {
  BaseWidget,
  HotspotWidget,
} from 'shared/src/features/widgets/widgets.types';
import {
  calculateWidgetTextColor,
  getWidgetArrowPlacement,
  retrieveWidthStyles,
} from 'shared/src/features/widgets/widgets.utils';
import WidgetHotspotBeacon from 'shared/src/components/widgets/WidgetHotspotBeacon';
import WidgetFooter from 'shared/src/components/widgets/WidgetFooter';
import WidgetHotspotLayout from 'shared/src/components/widgets/WidgetHotspotLayout';
import WidgetAnimation from 'shared/src/components/widgets/WidgetAnimation';
import WidgetBody from 'shared/src/components/widgets/WidgetBody/WidgetBody';
import WidgetText from 'shared/src/components/widgets/WidgetText';

import { WidgetComponentProps } from '../Widget';
import widgetPositionStyles from '../../WidgetPositionManagerHtml/WidgetPositionManagerHtml.module.css';
import styles from './Hotspot.module.css';
import {
  DemoPlayerArrowWidgetId,
  DemoPlayerHotspotClickableAreaId,
} from '../../../constants';
import { DemoPlayerWidget } from '../../../types';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';

const Hotspot: VFC<WidgetComponentProps<HotspotWidget>> = ({
  widget,
  onCta,
  isMounted,
  isDemoPlayerSmallScreen,
  onPrev,
  onExit,
  onReady,
  onSecondary,
  onDismiss,
  animationDelayMs,
  durationIn,
  durationOut,
}) => {
  const { options, hotspotOnlyBeacon } = widget;
  const autoAlignment = widget.autoAlignment;

  const beaconColor = options.root.beaconColor;
  const beaconOpacity = options.root.beaconOpacity;
  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth, widget.options.root.maxFrameWidth]
  );
  const autoHideText = options.root.auto_hide_text;
  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';

  const [isTextVisible, setIsTextVisible] = useState(true);

  // When text content is rendered for the first time it shouldn't hide on mouse leave,
  // this logic only enables when user leaves mouse from beacon and hover it again
  const [isInitialVisibility, setIsInitialVisibility] = useState(true);

  const textContentRef = useRef<HTMLDivElement>(null);
  const offsetPlaceholderRef = useRef<HTMLDivElement>(null);

  // Disable pointer events on transition end if opacity == 0
  const transitionEndHandler = useCallback(() => {
    const textContent = textContentRef.current;
    const offsetPlaceholder = offsetPlaceholderRef.current;

    if (textContent && offsetPlaceholder) {
      const opacity = window.getComputedStyle(textContent).opacity;

      if (opacity === '0') {
        textContent.style.pointerEvents = 'none';
        offsetPlaceholder.style.pointerEvents = 'none';
      }
    }
  }, []);

  useEffect(() => {
    const textContent = textContentRef.current;
    textContent?.addEventListener('transitionend', transitionEndHandler);

    return () => {
      textContent?.removeEventListener('transitionend', transitionEndHandler);
    };
  }, [transitionEndHandler]);

  const handleBeaconMouseEnter = useCallback(() => {
    if (!autoHideText) return;

    setIsInitialVisibility(false);

    const textContent = textContentRef.current;
    const offsetPlaceholder = offsetPlaceholderRef.current;
    if (textContent && offsetPlaceholder) {
      setIsTextVisible(true);
      textContent.style.pointerEvents = 'all';
      offsetPlaceholder.style.pointerEvents = 'all';
    }
  }, [autoHideText]);

  const handleBeaconMouseLeave = useCallback(() => {
    if (!autoHideText) return;

    setIsTextVisible(false);
  }, [autoHideText]);

  const handleWidgetMouseEnter = useCallback(() => {
    if (!autoHideText || isInitialVisibility) return;

    const textContent = textContentRef.current;
    const offsetPlaceholder = offsetPlaceholderRef.current;
    if (textContent && offsetPlaceholder) {
      setIsTextVisible(true);
      textContent.style.pointerEvents = 'all';
      offsetPlaceholder.style.pointerEvents = 'all';
    }
  }, [autoHideText, isInitialVisibility]);

  const handleWidgetMouseLeave = useCallback(() => {
    if (!autoHideText || isInitialVisibility) return;

    setIsTextVisible(false);
  }, [autoHideText, isInitialVisibility]);

  const onlyBeacon = hotspotOnlyBeacon || isDemoPlayerSmallScreen;

  return (
    <WidgetHotspotLayout
      id={DemoPlayerArrowWidgetId}
      onlyBeacon={onlyBeacon}
      alignment={autoAlignment}
    >
      <WidgetHotspotLayout.Beacon>
        <div
          className={cx(styles.hotspotDot, widgetPositionStyles.noAnchorFound)}
          onMouseEnter={handleBeaconMouseEnter}
          onMouseLeave={handleBeaconMouseLeave}
        >
          <div
            className={styles.beaconClickableArea}
            onClick={onCta}
            id={DemoPlayerHotspotClickableAreaId}
          />
          <WidgetAnimation
            in={isMounted}
            durationIn={durationIn}
            durationOut={durationOut}
            delayIn={animationDelayMs}
            onEnterAnimationEnd={onlyBeacon ? onReady : undefined}
            onExitAnimationEnd={onlyBeacon ? onExit : undefined} // quick fix: invoke `onExit` callback on beacon when there is no widget body
          >
            <WidgetHotspotBeacon color={beaconColor} opacity={beaconOpacity} />
          </WidgetAnimation>
        </div>
      </WidgetHotspotLayout.Beacon>
      {!onlyBeacon && (
        <WidgetHotspotLayout.Body>
          <div
            className={styles.offsetPlaceholder}
            onMouseEnter={handleWidgetMouseEnter}
            onMouseLeave={handleWidgetMouseLeave}
            ref={offsetPlaceholderRef}
          />
          <WidgetAnimation
            in={isMounted}
            slideIn={getWidgetArrowPlacement(autoAlignment)}
            durationIn={durationIn}
            durationOut={durationOut}
            delayIn={animationDelayMs}
            onEnterAnimationEnd={onReady}
            onExitAnimationEnd={onExit}
          >
            <div
              ref={textContentRef}
              className={cx(styles.hotspotRoot, {
                [styles.hotspotRootTextVisible]: isTextVisible,
              })}
              onMouseEnter={handleWidgetMouseEnter}
              onMouseLeave={handleWidgetMouseLeave}
              onClick={onCta}
            >
              <WidgetBody
                {...widthStyles}
                className={styles.hotspot}
                widget={widget as unknown as DemoPlayerWidget<BaseWidget>}
                autoAlignment={autoAlignment}
                onDismiss={onDismiss}
                id={`hotspot_widget_${widget.id}`}
                testId={`widget_${widget.kind}_${widget.id}`}
              >
                <WidgetText
                  id={`hotspot_widget_text_${widget.id}`}
                  aria-label={`Widget Text Content for ${widget.id}`}
                  value={options.description.value}
                  style={{
                    color: calculateWidgetTextColor(
                      widgetBackgroundColor,
                      'primary'
                    ),
                  }}
                />
                <WidgetFooter
                  stopClickPropagation
                  totalStepsNumber={widget._totalStepsCount}
                  currentStepNumber={widget._currentStepNumber}
                  widget={widget}
                  onSecondary={onSecondary}
                  onPrev={onPrev}
                  onCta={onCta}
                  showPrevButton={isWidgetPreviousButtonEnabled(widget)}
                  id={`hotspot_widget_footer_${widget.id}`}
                />
              </WidgetBody>
            </div>
          </WidgetAnimation>
        </WidgetHotspotLayout.Body>
      )}
    </WidgetHotspotLayout>
  );
};

export default Hotspot;
