import { useCallback, useContext, useEffect, useState } from 'react';
import { ProjectId } from 'shared/src/features/project/project.types';

import styles from './Restart.module.css';
import ReplayButton from '../ReplayButton';
import { useRestartPlayerState } from '../../hooks/useRestartPlayerState';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import CenterElementWithOverlay from '../CenterElementWithOverlay';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';

const Restart = ({ projectId }: { projectId: ProjectId }) => {
  const { isResponsiveEnabled } = useContext(DemoPlayerContext);
  const { isWaitingRestartState, restartPlayer, replayButtonConfig } =
    useRestartPlayerState();
  const [isShow, setIsShow] = useState(isWaitingRestartState);

  const { isPreview } = useRendererPlayerState();

  const handleExited = useCallback(() => {
    restartPlayer();
  }, [restartPlayer]);

  const handleClick = useCallback(() => {
    setIsShow(false);
  }, []);

  useEffect(() => {
    setIsShow(isWaitingRestartState);
  }, [isWaitingRestartState]);

  // TODO: fix: https://storylane.atlassian.net/browse/STORY-3301
  const handleEditButtonClick = useCallback(() => {
    window.top?.postMessage({
      type: 'DemoPlayerStartProjectEdit',
      payload: {
        projectId,
      },
    });
  }, [projectId]);

  return (
    <CenterElementWithOverlay enabled={isShow} onExit={handleExited}>
      {isPreview ? (
        <div className={styles.previewModeText}>
          Click to{' '}
          <span role="button" onClick={handleEditButtonClick}>
            Edit Demo
          </span>
        </div>
      ) : (
        <ReplayButton
          isResponsive={isResponsiveEnabled}
          config={replayButtonConfig}
          onClick={handleClick}
        />
      )}
    </CenterElementWithOverlay>
  );
};

export default Restart;
