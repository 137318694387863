import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './Spinner.module.css';
import { Variant } from '../../features/ui/ui.types';

const Spinner: FunctionComponent<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    size?: number;
    variant?: Variant | 'gray';
  }
> = ({ className, variant = 'magenta', size = 60, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      className={cx(
        styles.root,
        {
          [styles.rootMagenta]: variant === 'magenta',
          [styles.rootGreen]: variant === 'green',
          [styles.rootPurple]: variant === 'purple',
          [styles.rootYellow]: variant === 'yellow',
          [styles.rootGray]: variant === 'gray',
        },
        className
      )}
      style={{
        width: size,
        height: size,
        fontSize: size,
      }}
    />
  );
};

export default Spinner;
