import { sessionStorageWithFallback } from '../../utils/storageWithFallback';
import {
  AudioAutoplaySessionKey,
  VideoAutoplaySessionKey,
} from './audioVideoSession.constants';

export function setSessionAudioAutoplay(shouldAutoplay: boolean): void {
  sessionStorageWithFallback.setItem(
    AudioAutoplaySessionKey,
    shouldAutoplay ? 'yes' : 'no'
  );
}

export function setSessionVideoAutoplay(shouldAutoplay: boolean): void {
  sessionStorageWithFallback.setItem(
    VideoAutoplaySessionKey,
    shouldAutoplay ? 'yes' : 'no'
  );
}

export function getSessionVideoAutoplay(): boolean {
  const autoplay = sessionStorageWithFallback.getItem(VideoAutoplaySessionKey);

  return autoplay !== 'no';
}

export function getSessionAudioAutoplay(): boolean {
  const autoplay = sessionStorageWithFallback.getItem(AudioAutoplaySessionKey);

  return autoplay !== 'no';
}
