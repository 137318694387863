import { VFC } from 'react';
import cx from 'classnames';
import VideoContextProvider from 'shared/src/components/Video/VideoContextProvider';

import styles from './DemoVideo.module.css';
import DemoVideoForPlayer from './DemoVideoForPlayer';

type VideoProps = {
  src: string;
  startTime?: number;
  endTime?: number;
  onEnded?: () => void;
  isPreloadingHidden: boolean;
  isMutedInitial: boolean;
  playbackRateInitial: number;
  isHlsVideo: boolean;
  thumbnailUrl?: string;
};

const DemoVideo: VFC<VideoProps> = ({
  src,
  startTime = 0,
  endTime,
  onEnded,
  isPreloadingHidden,
  isMutedInitial,
  playbackRateInitial,
  isHlsVideo,
  thumbnailUrl,
}) => {
  return (
    <div
      className={cx(styles.videoWrapper, {
        [styles.videoWrapperPreloadingHidden]: isPreloadingHidden,
      })}
    >
      <VideoContextProvider
        defaultValues={{
          isMuted: isMutedInitial,
          playbackRate: playbackRateInitial,
          isHlsVideo,
        }}
      >
        <DemoVideoForPlayer
          src={src}
          startTime={startTime}
          endTime={endTime}
          onEnded={onEnded}
          isPreloadingHidden={isPreloadingHidden}
          thumbnailUrl={thumbnailUrl}
        />
      </VideoContextProvider>
    </div>
  );
};

export default DemoVideo;
