import { useCallback, useContext, useEffect, useState, VFC } from 'react';
import { createPortal } from 'react-dom';
import { WidgetTriggerThenType } from 'shared/src/features/widgets/widgets.constants';
import {
  WidgetTrigger,
  WithCta,
  WithSecondary,
} from 'shared/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isVideoClip,
} from 'shared/src/features/widgets/widgets.utils';
import VideoOver from 'shared/src/components/VideoOver';

import Widget from '../Widget';
import styles from './WidgetManagerImage.module.css';
import WidgetPositionManagerImage from '../WidgetPositionManagerImage';
import WidgetCircleAnimation from '../WidgetCircleAnimation';
import { DemoPlayerProps } from '../DemoPlayer/DemoPlayer';
import WidgetBottomTray from '../WidgetBottomTray';
import WidgetBackdropManagerImage from '../WidgetBackdropManagerImage';
import { isCtaTargetsExternalUrl } from '../../utils/isCtaTargetsExternalUrl';
import { useDemoWidgetAutoplay } from '../../hooks/useDemoWidgetAutoplay';
import { useDemoWidgetHotkeys } from '../../hooks/useDemoWidgetHotkeys';
import { useWidgetPlayerState } from '../../hooks/useWidgetPlayerState';
import { useFlowlistPlayerState } from '../../hooks/useFlowlistPlayerState';
import { useDemoIsSmallScreen } from '../../hooks/useDemoIsSmallScreen';
import { demoplayerEventCreators } from '../../events/eventCreators';
import { DemoPlayerWidgetContext } from '../../contexts/DemoPlayerWidgetContext';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import VoiceOverAudio from '../VoiceOverAudio';

enum WidgetUserAction {
  Cta = 'cta',
  Prev = 'prev',
  Dismiss = 'dismiss',
  Secondary = 'secondary',
  Anchor = 'anchor',
}

interface WidgetManagerImageProps {
  isRendererReady: boolean;
  autoplayTimeoutSeconds: number;
  onOpenExtUrl?: DemoPlayerProps['onOpenExtUrl'];
}

const WidgetManagerImage: VFC<WidgetManagerImageProps> = (props) => {
  const { isRendererReady, onOpenExtUrl, autoplayTimeoutSeconds } = props;

  const {
    widget,
    widgetSlotElement,
    rendererElement,
    currentPageId,
    goPrevWidget,
    goNextWidget,
    onDismiss,
    goToFlow,
    isRecording,
    isFlowPlaybackState,
    isWidgetTranslationAnimationEnabled,
    widgetAnimationDelayMs,
    widgetAnimationDurationInMs,
    widgetAnimationDurationOutMs,
    isChecklistEnabled,
  } = useWidgetPlayerState();
  const {
    checklist,
    maximizeChecklist,
    checklistIsMinimized,
    minimizeChecklist,
  } = useFlowlistPlayerState();
  const { displayWidget, setDisplayWidget } = useContext(
    DemoPlayerWidgetContext
  );
  const {
    isAudioAutoplayEnabled,
    setIsAudioAutoplayEnabled,
    emitter,
    isVideoAutoplayEnabled,
    setIsVideoAutoplayEnabled,
  } = useContext(DemoPlayerContext);

  const isDemoPlayerSmallScreen = useDemoIsSmallScreen();

  const [widgetActionType, setWidgetActionType] = useState<
    WidgetUserAction | undefined
  >();

  useEffect(() => {
    if (isFlowPlaybackState) {
      setDisplayWidget(true);
    }
  }, [widget?.id, isFlowPlaybackState, setDisplayWidget]);

  const handlePrevButtonClick = useCallback(() => {
    setDisplayWidget(false);
    setWidgetActionType(WidgetUserAction.Prev);
  }, [setDisplayWidget]);

  const handleCtaButtonClick = useCallback(() => {
    if (widget) {
      emitter.emit(demoplayerEventCreators.widgetCtaClickEvent, {
        widgetId: widget.id,
      });

      if (isCtaTargetsExternalUrl(widget) && onOpenExtUrl && !isRecording) {
        const isBlank = widget.options.cta.extUrlBlank;
        onOpenExtUrl(
          {
            url: widget.options.cta.extUrl,
            target: isBlank ? '_blank' : '_self',
          },
          widget.id
        );
      }

      setDisplayWidget(false);
      setWidgetActionType(WidgetUserAction.Cta);
    }
  }, [widget, emitter, onOpenExtUrl, isRecording, setDisplayWidget]);

  const handleSecondaryButtonClick = useCallback(() => {
    if (widget) {
      emitter.emit(demoplayerEventCreators.widgetSecondaryClickEvent, {
        widgetId: widget.id,
      });

      const secondary = (widget.options as unknown as WithSecondary).secondary;
      const nextExternalUrl = secondary?.extUrl; // secondary button navigates to external url
      const isTargetBlank = secondary?.extUrlBlank;
      const shouldDismiss = secondary?.shouldDismiss;
      if (nextExternalUrl) {
        if (onOpenExtUrl) {
          onOpenExtUrl(
            {
              url: nextExternalUrl,
              target: isTargetBlank ? '_blank' : '_self',
            },
            widget.id
          );
        }
        // `widget.options.secondary?.flowId` - fallback incorrect "secondary" button which doesn't have both `extUrl` and `flowId`
      } else if (widget.options.secondary?.flowId) {
        // start widget fade out animation; on animation done - trigger secondary button action
        setDisplayWidget(false);
        setWidgetActionType(WidgetUserAction.Secondary);
      } else if (shouldDismiss) {
        onDismiss();
      }
    }
  }, [widget, onOpenExtUrl, onDismiss, emitter, setDisplayWidget]);

  const handleCloseButtonClick = useCallback(() => {
    setDisplayWidget(false);
    setWidgetActionType(WidgetUserAction.Dismiss);
  }, [setDisplayWidget]);

  const autoplayTimerCallback = useCallback(() => {
    handleCtaButtonClick();
  }, [handleCtaButtonClick]);

  const isAutoplayEnabled = autoplayTimeoutSeconds > 0;
  const {
    rerunTimer: rerunAutoplayTimer,
    isTimerRunning: isAutoplayTimerRunning,
  } = useDemoWidgetAutoplay({
    onTimerFired: autoplayTimerCallback,
    configInterval: autoplayTimeoutSeconds * 1000,
    disabled: !isAutoplayEnabled || (isRecording && !isRendererReady),
  });

  const handleFlowlistLaunchClick = useCallback(() => {
    (checklistIsMinimized ? maximizeChecklist : minimizeChecklist)();
  }, [checklistIsMinimized, maximizeChecklist, minimizeChecklist]);

  const onMediaEnded = useCallback(() => {
    if (isAutoplayEnabled) {
      autoplayTimerCallback();
    }
  }, [autoplayTimerCallback, isAutoplayEnabled]);

  /**
   * Perform machine transition once widget is exited
   */
  const handleWidgetExitUnmount = useCallback(() => {
    if (!widget) return;

    // only "anchor" and "cta" are treated as "go-forward" which should restart autoplay timer
    if (
      (widgetActionType === WidgetUserAction.Anchor ||
        widgetActionType === WidgetUserAction.Cta) &&
      isAutoplayEnabled &&
      isAutoplayTimerRunning
    ) {
      rerunAutoplayTimer();
    }

    const anchorInteractionEqualsCta =
      widgetActionType === WidgetUserAction.Anchor &&
      isAnchorBasedWidget(widget) &&
      widget.options.root.trigger === null;
    if (
      widgetActionType === WidgetUserAction.Cta ||
      anchorInteractionEqualsCta
    ) {
      if (isCtaTargetsExternalUrl(widget) && !isRecording) {
        const isBlank = widget.options.cta.extUrlBlank;
        if (!isBlank) {
          return;
        }
      }
      const widgetOptions = widget.options as unknown as WithCta;
      if (widgetOptions.cta?.flowId) {
        goToFlow(widgetOptions.cta.flowId, widgetOptions.cta.widgetId);
        return;
      }
      goNextWidget();
    } else if (widgetActionType === WidgetUserAction.Secondary) {
      /**
       * Request machine transition to target flow.
       * External url is handled in `handleSecondaryButtonClick` as it shouldn't fade out widget.
       */
      goToFlow(
        widget.options.secondary?.flowId,
        widget.options.secondary.widgetId
      );
      /**
       * FIX usecase:
       * 1. secondary button target is the same flow which current widget belongs to;
       * 2. widget is the first one in the flow.
       *
       * Widget exited, secondary button callback invoked, but `widget.id` change doesn't happen.
       * `WidgetPositionManager` component is nor remounted, hence we are stuck here.
       * @see {@link https://storylane.atlassian.net/browse/STORY-135 issue}
       *
       * TODO: remove it if appear-exit state is moved to state machine or correct way is found
       */
      const noFlowChange = widget.options.secondary?.flowId === widget.flowId;
      const isFirstWidgetinFlow = !widget._prevId;
      if (noFlowChange && isFirstWidgetinFlow) {
        setDisplayWidget(true);
        setWidgetActionType(undefined);
      }
    } else if (
      widgetActionType === WidgetUserAction.Anchor &&
      isAnchorBasedWidget(widget)
    ) {
      const trigger = widget.options.root.trigger as WidgetTrigger; // see "cta" condition above to learn about `trigger === null`
      if (
        trigger.then.type === WidgetTriggerThenType.ExternalUrl &&
        onOpenExtUrl
      ) {
        onOpenExtUrl(
          {
            url: trigger.then.value,
            target: '_blank',
          },
          widget.id
        );
      }
      goNextWidget();
    } else if (widgetActionType === WidgetUserAction.Dismiss) {
      onDismiss();
    } else if (widgetActionType === WidgetUserAction.Prev) {
      goPrevWidget();
    } else {
      throw new Error('Invalid exit user action');
    }
  }, [
    widget,
    widgetActionType,
    isAutoplayEnabled,
    isAutoplayTimerRunning,
    rerunAutoplayTimer,
    isRecording,
    goNextWidget,
    goToFlow,
    setDisplayWidget,
    onOpenExtUrl,
    onDismiss,
    goPrevWidget,
  ]);

  const handleWidgetReady = useCallback(() => {
    if (widget)
      emitter.emit(demoplayerEventCreators.widgetReadyEvent, {
        widgetId: widget.id,
      });
  }, [widget, emitter]);

  useDemoWidgetHotkeys();

  if (
    !widget ||
    !isFlowPlaybackState ||
    widget.page_id !== currentPageId ||
    !rendererElement
  ) {
    return null;
  }

  return (
    <>
      {isRendererReady &&
        !isVideoClip(widget) &&
        widgetSlotElement &&
        createPortal(
          <div className={styles.root}>
            <WidgetPositionManagerImage
              key={widget.id} // re-mount on widget change to drop all position related styles
              widgetSlotElement={widgetSlotElement}
              widget={widget}
              isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
            >
              <Widget
                widget={widget}
                isMounted={displayWidget}
                onPrev={handlePrevButtonClick}
                onClose={handleCloseButtonClick}
                onCta={handleCtaButtonClick}
                onSecondary={handleSecondaryButtonClick}
                onReady={handleWidgetReady}
                onExit={handleWidgetExitUnmount}
                animationDelayMs={widgetAnimationDelayMs}
                durationIn={widgetAnimationDurationInMs}
                durationOut={widgetAnimationDurationOutMs}
                isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
                isAudioAutoplayEnabled={isAudioAutoplayEnabled}
                setIsAudioAutoplayEnabled={setIsAudioAutoplayEnabled}
              />
            </WidgetPositionManagerImage>
            <WidgetBackdropManagerImage
              isMounted={displayWidget}
              animationDelayMs={widgetAnimationDelayMs}
              widget={widget}
              onClick={handleCtaButtonClick} // Image-project might have a clickable area instead of an anchor element. Click equals to "cta" button clock
              durationIn={widgetAnimationDurationInMs}
              durationOut={widgetAnimationDurationOutMs}
            />
            {isWidgetTranslationAnimationEnabled && (
              <WidgetCircleAnimation widget={widget} />
            )}
          </div>,
          widgetSlotElement
        )}
      {isDemoPlayerSmallScreen && (
        <WidgetBottomTray
          flowlist={checklist}
          onFlowlistLaunch={handleFlowlistLaunchClick}
          onCta={handleCtaButtonClick}
          onPrev={handlePrevButtonClick}
          onSecondary={handleSecondaryButtonClick}
          widget={widget}
          rounded
          showFlowlistLaunch={isChecklistEnabled}
        />
      )}
      {widget?.audio_url && isRendererReady && (
        <VoiceOverAudio
          audioUrl={widget?.audio_url}
          alignment={widget?.media_position}
          isAudioAutoplayEnabled={isAudioAutoplayEnabled}
          duration={widget.media_duration || 0}
          onAudioEnded={onMediaEnded}
          onPlayPauseButtonClick={setIsAudioAutoplayEnabled}
        />
      )}

      {widget.overlay_video?.file_url && isRendererReady && (
        <VideoOver
          mediaDuration={widget.media_duration}
          streamStatus={widget?.overlay_video?.stream_status}
          streamUrl={widget?.overlay_video?.stream_url}
          onEnded={onMediaEnded}
          position={widget.media_position}
          thumbnailUrl={widget?.overlay_video?.thumbnail_url}
          isAutoPlay={isVideoAutoplayEnabled}
          onPlayPauseButtonClick={setIsVideoAutoplayEnabled}
        />
      )}
    </>
  );
};

export default WidgetManagerImage;
