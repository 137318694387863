import { WithCta } from 'shared/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isModalLikeWidget,
  isVendorForm,
} from 'shared/src/features/widgets/widgets.utils';

import { DemoPlayerWidget } from '../types';

/**
 * "cta" is enabled(presented) and targets external URL.
 * @param widget
 * @returns
 */
export const isCtaTargetsExternalUrl = (
  widget: DemoPlayerWidget
): widget is DemoPlayerWidget & {
  options: DemoPlayerWidget['options'] &
    (WithCta & { cta: { extUrl: string } });
} => {
  if (
    (isModalLikeWidget(widget) && !isVendorForm(widget)) || // modal-like widgets(except `vendor_form`) always have a "cta" button
    (isAnchorBasedWidget(widget) && widget.options.root.next_button) // "cta" is enabled for anchor-based widget
  ) {
    return Boolean(widget.options.cta.extUrl);
  }
  return false;
};
