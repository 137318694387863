import cx from 'classnames';
import { VFC, memo } from 'react';

import WidgetFooterLayout from '../WidgetFooterLayout';
import styles from './WidgetFooter.module.css';
import {
  calculateWidgetTextColor,
  getWidgetButtonStyles,
  isCtaButtonEnabled,
  isModalLikeWidget,
  isSecondaryButtonEnabled,
  isVendorForm,
} from '../../../features/widgets/widgets.utils';
import WidgetButton from '../WidgetButton';
import {
  BaseWidget,
  WithSecondary,
} from '../../../features/widgets/widgets.types';
import Icon from '../../../uikit/Icon';
import WidgetFooterSteps from '../WidgetFooterSteps';

interface WidgetFooterProps {
  stopClickPropagation?: boolean;
  currentStepNumber: number;
  totalStepsNumber: number;
  showPrevButton: boolean;
  widget: BaseWidget;
  onPrev: () => void;
  onCta: () => void;
  onSecondary: () => void;
  id?: string;
}

const WidgetFooter: VFC<WidgetFooterProps> = (props) => {
  const {
    stopClickPropagation,
    widget,
    currentStepNumber,
    totalStepsNumber,
    showPrevButton,
    id,
  } = props;

  if (isVendorForm(widget)) {
    // Vendor form widget is the only one which doesn't/shouldn't have footer.
    throw new Error('Incorrect widget kind: ' + widget.kind);
  }

  const { options } = widget;
  const widgetBackgroundColor = options.root.backgroundColor || '#fff';
  const showSteps = options.root.show_step_number;
  const showSecondary =
    isSecondaryButtonEnabled(widget) && widget.options.secondary?.value;
  const showCta =
    isCtaButtonEnabled(widget) &&
    (widget as BaseWidget & { cta: string }).cta.length !== 0;

  const showFooter = showPrevButton || showSteps || showSecondary || showCta;

  if (!showFooter) {
    return null;
  }

  return (
    <div
      className={styles.root}
      onClick={(e) => {
        if (stopClickPropagation === true) {
          e.stopPropagation();
        }
      }}
    >
      <WidgetFooterLayout
        id={id}
        hasPrev={showPrevButton}
        widget={widget}
        aria-label="Widget footer"
      >
        {showPrevButton && (
          <WidgetFooterLayout.Area type="prev">
            <button
              className={styles.prev}
              onClick={(e) => {
                e.stopPropagation();
                props.onPrev();
              }}
              style={{
                color: calculateWidgetTextColor(
                  widgetBackgroundColor,
                  'secondary'
                ),
              }}
            >
              <Icon name="widget-prev-step" />
            </button>
          </WidgetFooterLayout.Area>
        )}
        {showSteps && (
          <WidgetFooterLayout.Area type="step">
            <WidgetFooterSteps
              color={calculateWidgetTextColor(
                widgetBackgroundColor,
                'secondary'
              )}
              curStep={currentStepNumber}
              totalSteps={totalStepsNumber}
            />
          </WidgetFooterLayout.Area>
        )}
        <ReadOnlyButtons
          widget={widget}
          onCta={props.onCta}
          onSecondary={props.onSecondary}
        />
      </WidgetFooterLayout>
    </div>
  );
};

interface ReadOnlyButtonsProps {
  widget: BaseWidget;
  onCta: () => void;
  onSecondary: () => void;
}
const ReadOnlyButtons: VFC<ReadOnlyButtonsProps> = ({
  widget,
  onSecondary,
  onCta,
}) => {
  const ctaTextValue = (widget as BaseWidget & { cta: string }).cta;
  const secondaryTextValue =
    (widget.options as Partial<WithSecondary>).secondary?.value || '';
  const showSecondary =
    isSecondaryButtonEnabled(widget) && widget.options.secondary?.value;
  const showCta = isCtaButtonEnabled(widget) && ctaTextValue.length !== 0;

  if (!showCta && !showSecondary) {
    return null;
  }

  return (
    <WidgetFooterLayout.Area type="buttons">
      {showSecondary && (
        <button
          className={cx(styles.button, {
            [styles.buttonLarge]: isModalLikeWidget(widget),
          })}
          style={getWidgetButtonStyles(widget.options.secondary)}
          onClick={(e) => {
            e.stopPropagation();
            onSecondary();
          }}
          data-testid="widget-secondary"
        >
          <WidgetButton value={secondaryTextValue} multiline />
        </button>
      )}
      {showCta && (
        <button
          className={cx(styles.button, {
            [styles.buttonLarge]: isModalLikeWidget(widget),
          })}
          style={getWidgetButtonStyles(widget.options.cta)}
          onClick={(e) => {
            e.stopPropagation();
            onCta();
          }}
          data-testid="widget-cta"
        >
          <WidgetButton value={ctaTextValue} multiline />
        </button>
      )}
    </WidgetFooterLayout.Area>
  );
};

export default memo(WidgetFooter);
