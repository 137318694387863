import { ProjectPageKind } from 'shared/src/features/projectPages/projectPages.constants';
import { ProjectPageId } from 'shared/src/features/projectPages/projectPages.types';
import { RendererImagePageToFileMapItem } from 'shared/src/features/renderer/renderer.types';
import { extLocalVideoFieldName } from 'shared/src/features/widgets/widgets.constants';
import { localStorageWithFallback } from 'shared/src/utils/storageWithFallback';

import { DemoPlayerProjectPage } from '../types';

/**
 * Create a map of page file urls.
 * @param pages project pages collection.
 */
export const getPageToFileUrlMap = (
  pages: DemoPlayerProjectPage[]
): Record<ProjectPageId, RendererImagePageToFileMapItem> => {
  return pages.reduce<{
    [key: string]: RendererImagePageToFileMapItem;
  }>((hash, page) => {
    hash[page.id] = {
      type: page.kind,
      url:
        page.kind === ProjectPageKind.Video
          ? page.stream_url ||
            localStorageWithFallback.getItem(extLocalVideoFieldName) ||
            ''
          : page.file_url,
      isFromLocalStorage:
        page.kind === ProjectPageKind.Video && !page.stream_url,
      thumbnailUrl: page.thumbnail_url + '?height=179&width=224',
      bgUrl: page.bg_image_file_url || undefined,
    };
    return hash;
  }, {});
};
